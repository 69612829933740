import I from '../types.ts';

const getFieldsOrder: I['getFieldsOrder'] = function () {
    const { joinScript } = this.state;
    const { executorOrganization } = joinScript!;

    const fieldsOrder = ['secondName', 'firstName', 'thirdName', 'telegram'];

    if (!['SMZ', 'IP'].includes(executorOrganization)) {
        fieldsOrder.push('inn');
    }

    fieldsOrder.push(...['type', 'country', 'town']);

    return fieldsOrder as ReturnType<I['getFieldsOrder']>;
};

export default getFieldsOrder;
