import { store } from '../../redux/redux';
import changePage from '../changePage';
import changeCorporation from './changeCorporation';

export default function toSignContract({ corporationId }) {
    const { user } = store.getState();

    const authCorporation = () =>
        new Promise((resolve) => {
            if (user.idOfCurrentCorporation === corporationId) {
                resolve();
            } else {
                changeCorporation({ id: corporationId, toPageIndex: false }).then(() => {
                    resolve();
                });
            }
        });

    return new Promise((resolve) => {
        authCorporation().then(() => {
            changePage({ href: '' });

            resolve();
        });
    });
}
