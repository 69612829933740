import axios from 'axios';
import getHeaders from '../getHeaders';
import { dispatcher, store } from '../../redux/redux';
import changePage from '../changePage';

export default async function handlerFns({ isAdd, isCheck, isChangePage }) {
    const { user } = store.getState();

    this.setState({
        error: undefined,
    });

    await this.handlerLoading('saveFns');

    try {
        const res = await axios.patch(
            `${process.env.REACT_APP_API}/executor`,
            {
                action: 'handler-fns',
                isAdd,
                isCheck,
            },
            { headers: getHeaders() },
        );

        const { success, data } = res.data;

        if (success) {
            const { isJurStatusActive, isJurStatusProcess, fnsTax } = data;
            const fields = {};

            if (isJurStatusActive !== undefined) {
                fields.isJurStatusActive = isJurStatusActive;
            }

            if (isJurStatusProcess !== undefined) {
                fields.isJurStatusProcess = isJurStatusProcess;
            }

            if (fnsTax !== undefined) {
                fields.fnsTax = fnsTax;
            }

            dispatcher({
                type: 'user',
                data: { ...user, ...fields },
            }).then(() => {
                if (isChangePage) {
                    changePage({ href: 'settings/fns' });
                }

                document.dispatchEvent(new CustomEvent('changeUser', { detail: data }));
            });
        } else {
            const { message } = data;

            if (message === 'Account not adding for company') {
                this.setState({
                    error: 'account-not-adding',
                });
            } else if (message === 'Account not found') {
                this.setState({
                    error: 'account-not-found',
                });
            } else {
                this.setState({
                    error: 'fns-error',
                });
            }
        }

        this.handlerLoading(null);
    } catch (error) {
        this.handlerLoading(null);
    }
}
