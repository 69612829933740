import React from 'react';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import I, { BlockT } from '../types.ts';

const walletInfo = {
    account: {
        content: 'Реквизиты счёта',
    },
    card: {
        content: 'Номер карты',
    },
} as const;

const renderWallet = function (this: I, { prop }: { prop: string }) {
    return (
        <div className="join__blockWalletBlock">
            {prop === 'card' ? (
                <>{this.renderField({ name: 'cardNumber' })}</>
            ) : (
                <>
                    <div className="join__blockBank">
                        <div className="join__blockBankInner">
                            <div className="join__blockBankTitle">
                                Счет обязательно должен принадлежать вам
                            </div>
                            <div className="join__blockBankDescription">
                                Если указать реквизиты другого человека, деньги не дойдут
                            </div>
                        </div>
                    </div>

                    {(['bic', 'accountNumber', 'addressatName'] as const).map((fieldName) =>
                        this.renderField({ name: fieldName }),
                    )}
                </>
            )}
        </div>
    );
};

const renderCardHead = function () {
    return (
        <>
            <div className="join__blockTitle">Укажите номер банковской&nbsp;карты</div>
            <p className="join__blockText">Она нужна, чтобы получать оплату.</p>
        </>
    );
};
const renderAccountHead = function () {
    return (
        <>
            <div className="join__blockTitle">
                Укажите банковские
                <br />
                реквизиты
            </div>
            <p className="join__blockText">
                Реквизиты нужны, чтобы получать оплату. Их можно узнать в&nbsp;свойствах счёта
                в&nbsp;приложении вашего банка.
            </p>
        </>
    );
};

const Card: BlockT = function () {
    const { joinScript, currentWalletType } = this.state;
    const { executorOrganization } = joinScript!;

    return (
        <>
            <div className="join__blockInner _col">
                {['FL', 'FL-A'].includes(executorOrganization) && <>{renderCardHead.call(this)}</>}
                {executorOrganization === 'SMZ' && (
                    <>
                        {renderAccountHead.call(this)}
                        <div className="join__blockWalletTabs _row">
                            {(['account', 'card'] as const).map((key) => {
                                const currentInfo = walletInfo[key];

                                return (
                                    <label className="join__blockWalletTab _click" key={key}>
                                        <input
                                            type="radio"
                                            className="join__blockWalletTabInput"
                                            checked={currentWalletType === key}
                                            onChange={() => {
                                                this.setState({ currentWalletType: key });
                                                // changeWalletType({ type: key });
                                            }}
                                        />
                                        <div className="join__blockWalletTabView _col">
                                            {currentInfo.content}
                                        </div>
                                    </label>
                                );
                            })}
                        </div>
                    </>
                )}
                {executorOrganization === 'IP' && <>{renderAccountHead.call(this)}</>}

                <ListAbsoluteMain
                    className="join__blockWalletBlocks"
                    items={[{ key: currentWalletType }]}
                    renderItem={renderWallet.bind(this)}
                    classNameItem="join__blockWalletBlock"
                    prop="key"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    isNotParamsItem={true}
                    callback={({ type }) => {
                        if (type === 'parent') {
                            this.updateBlocksKey();
                        }
                    }}
                    allItems={['account', 'card']}
                    currentItemKey={currentWalletType}
                />
                {this.renderError()}
                {this.renderButton({ name: 'card', text: 'Завершить', className: '_notTop' })}
                <div
                    className="join__blockBack _top"
                    onClick={async () => {
                        await this.updateHandler({
                            name: 'backCard',
                            nextStep: 'docs',
                            status: 'docs',
                        });
                    }}
                >
                    Вернуться к документам
                </div>
            </div>
        </>
    );
};

export default Card;
