const statuses = {
    FL: {
        title: 'Я физ. лицо (ГПХ)',
        description: 'Вы будете работать по договору гражданско-правового характера',
    },
    'FL-A': {
        title: 'Я физ. лицо (Агентский договор)',
        description: 'Вы будете работать по агентскому договору',
    },
    IP: {
        title: 'У меня ИП',
        description: 'У вас есть действующий<br/>статус индивидуального предпринимателя',
    },
    SMZ: {
        title: 'Я самозанятый',
        description: 'У вас есть действующий<br/>статус самозанятого',
    },
} as const;

export default statuses;
