import I from '../types.ts';

const updateBlocksKey: I['updateBlocksKey'] = function () {
    return new Promise((resolve) => {
        this.setState(
            {
                updatedKey: new Date().getTime(),
            },
            resolve,
        );
    });
};

export default updateBlocksKey;
