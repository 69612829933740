import axios from 'axios';
import getHeaders from './getHeaders';

export default function signContract({ itemsIds, code }) {
    return new Promise((resolve, reject) => {
        axios
            .patch(
                `${process.env.REACT_APP_API}/join-contract`, { itemsIds, isForce: true, code }, { headers: getHeaders() },
            )
            .then(
                (res) => {
                    const { success } = res.data;

                    if (success) {
                        resolve({});
                    } else {
                        reject(res.data);
                    }
                },
                () => null,
            );
    });
}