import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Window from '../../../components/chat/Window.jsx';

class Inner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { setOrderId } = this.props;

        return <Window setOrderId={setOrderId} />;
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Inner);

Inner.propTypes = {
    user: PropTypes.object,
    setOrderId: PropTypes.func,
};
