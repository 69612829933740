import I from '../types.ts';

const setStep: I['setStep'] = function (name, statusName) {
    return new Promise((resolve) => {
        this.setState(
            {
                currentStep: name,
                ...(statusName ? { currentStatusStep: statusName } : ({} as any)),
            },
            () => {
                this.checkTabsPosition();

                resolve();
            },
        );
    });
};

export default setStep;
