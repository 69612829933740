import React from 'react';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import I, { BlockT } from '../types.ts';

const renderField = function (this: I, { prop }: { prop: I['state']['currentSmzFormType'] }) {
    const name = `smz-${prop}` as const;

    return <div className="join__smzFormField">{this.renderField({ name })}</div>;
};

const StatusSmzForm: BlockT = function () {
    const { currentSmzFormType } = this.state;

    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__fnsLogo _col">
                    <img
                        src={require('../../../../img/fns-logo.svg').default}
                        alt=""
                        className="join__fnsLogoIcon"
                    />
                </div>
                <div className="join__blockTitle">
                    Отправьте запрос
                    <br />в «Мой Налог»
                </div>
                <div className="join__blockText">
                    Укажите телефон, с которым вы
                    <br />
                    регистрировались на сайте ФНС,
                    <br />
                    либо ваш ИНН.
                </div>
                <div className="join__smzForm">
                    <div className="join__smzFormTabs _row">
                        {(Object.keys(this.smzTabs) as (keyof typeof this.smzTabs)[]).map((key) => {
                            const content = key === 'phone' ? 'Номер телефона' : 'ИНН';

                            return (
                                <label className="join__smzFormTab" key={key}>
                                    <input
                                        type="checkbox"
                                        className="join__smzFormTabInput"
                                        checked={key === currentSmzFormType}
                                        onChange={() => {
                                            this.setState({ currentSmzFormType: key });

                                            // localStorage.setItem('crmJoinCurrentSmzFormType', key);
                                        }}
                                    />
                                    <div className="join__smzFormTabView">{content}</div>
                                </label>
                            );
                        })}
                    </div>
                    <ListAbsoluteMain
                        className="join__smzFormFields"
                        items={[{ key: currentSmzFormType }]}
                        renderItem={renderField.bind(this)}
                        classNameItem="join__smzFormField"
                        prop="key"
                        paramsParent={{ width: true }}
                        styles={[]}
                        isNotParamsItem={true}
                        currentItemKey={currentSmzFormType}
                        allItems={['phone', 'inn']}
                        callback={({ isChangeLen }) => {
                            if (isChangeLen) {
                                // handlerErrors({
                                //     errors: [{ name: 'smz-phone' }, { name: 'smz-inn' }],
                                //     action: 'delete',
                                // });
                            }
                        }}
                    />
                </div>
                {this.renderError()}
                {this.renderButton({
                    name: 'status-smz-add',
                    text: 'Отправить запрос',
                    // onClick: this.getHandlerButton(),
                    // withArrow: false,
                    className: '_notTop',
                })}
                <div
                    className="join__blockBack _top"
                    onClick={() => {
                        // setCurrentStep('phone');
                    }}
                >
                    Войти с другим номером телефона
                </div>
            </div>
        </>
    );
};

export default StatusSmzForm;
