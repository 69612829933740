import getRealParams from '@functions/getRealParams.ts';

import I from '../types.ts';

const checkTabsPosition: I['checkTabsPosition'] = function () {
    let { currentStep } = this.state;

    if (currentStep === 'reject') {
        currentStep = 'check';
    }

    const tabs = this.parent.current!.querySelector('.join__tabs') as HTMLElement;
    const tabsInner = tabs.querySelector('.join__tabsInner') as HTMLElement;
    const currentTab = tabsInner.querySelector(
        `.join__tabWrapper[data-name="${currentStep}"]`,
    ) as HTMLElement;

    if (currentTab) {
        const { getBoundingClientRect } = getRealParams({
            parent: this.parent.current!,
            elem: `.join__tabWrapper[data-name="${currentStep}"]`,
            width: this.parent.current!.offsetWidth,
            isClearStyles: true,
        });

        const left = (getBoundingClientRect as unknown as DOMRect).x;

        const resultLeft =
            -left + document.documentElement.clientWidth / 2 - currentTab.offsetWidth / 2;

        tabsInner.style.transform = `translate(${resultLeft}px,0)`;
    }
};

export default checkTabsPosition;
