import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import { CustomListenerT, StoreT } from '@global/types.ts';

import checkCode from './methods/checkCode.ts';
import checkCurrentStep from './methods/checkCurrentStep.ts';
import checkTabsPosition from './methods/checkTabsPosition.ts';
import getFieldsOrder from './methods/getFieldsOrder.ts';
import getHeaders from './methods/getHeaders.ts';
import getInfo from './methods/getInfo.ts';
import getInitFields from './methods/getInitFields.ts';
import handlerSocket from './methods/handlerSocket.ts';
import login from './methods/login.ts';
import setAsyncState from './methods/setAsyncState.ts';
import setStep from './methods/setStep.ts';
import stepHandler from './methods/stepHandler.ts';
import updateBlocksKey from './methods/updateBlocksKey.ts';
import updateHandler from './methods/updateHandler.ts';
import uploadFile from './methods/uploadFile.ts';
import visibilityDocChange from './methods/visibilityDocChange.ts';

import JoinI from './types.ts';

import renderBlock from './renders/renderBlock.tsx';
import renderBlocks from './renders/renderBlocks.tsx';
import renderButton from './renders/renderButton.tsx';
import renderContent from './renders/renderContent.tsx';
import renderDoc from './renders/renderDoc.tsx';
import renderError from './renders/renderError.tsx';
import renderField from './renders/renderField.tsx';
import renderHead from './renders/renderHead.tsx';
import renderTabs from './renders/renderTabs.tsx';
import renderTelegram from './renders/renderTelegram.tsx';
import blocks from './static/blocks.tsx';
import fields from './static/fields.tsx';
import smzTabs from './static/smzTabs.ts';
import statusBlocks from './static/statusBlocks.tsx';
import statuses from './static/statuses.ts';
import { tabs, tabsOrder } from './static/tabs.ts';

class Join extends EditBlock<JoinI['props'], JoinI['state']> implements JoinI {
    parent: JoinI['parent'];

    constructor(props: JoinI['props']) {
        super(props);
        this.state = {
            currentStep: 'start',
            currentStatusStep: 'statusStart',
            currentWalletType: 'card',
            currentSmzFormType: 'phone',
        };

        this.visibilityDocChange = this.visibilityDocChange.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);

        this.parent = React.createRef();
    }

    tabsOrder = tabsOrder;
    tabs = tabs;
    blocks = blocks;
    statusBlocks = statusBlocks;
    fields = fields;
    statuses = statuses;
    smzTabs = smzTabs;

    getInfo = getInfo;
    checkTabsPosition = checkTabsPosition;
    stepHandler = stepHandler;
    setStep = setStep;
    checkCode = checkCode;
    updateHandler = updateHandler;
    getFieldsOrder = getFieldsOrder;
    uploadFile = uploadFile;
    updateBlocksKey = updateBlocksKey;
    checkCurrentStep = checkCurrentStep;
    getInitFields = getInitFields;
    setAsyncState = setAsyncState;
    login = login;

    getHeaders = getHeaders;

    renderHead = renderHead;
    renderContent = renderContent;
    renderTabs = renderTabs;
    renderBlocks = renderBlocks;
    renderBlock = renderBlock;
    renderTelegram = renderTelegram;
    renderButton = renderButton;
    renderField = renderField;
    renderDoc = renderDoc;
    renderError = renderError;

    visibilityDocChange = visibilityDocChange;
    handlerSocket = handlerSocket;

    componentDidMount(): void {
        this.getInfo();

        (document.addEventListener as CustomListenerT)(
            'visibilityDocChange',
            this.visibilityDocChange,
        );
        (document.addEventListener as CustomListenerT)('getSocketData', this.handlerSocket);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)(
            'visibilityDocChange',
            this.visibilityDocChange,
        );
        (document.removeEventListener as CustomListenerT)('getSocketData', this.handlerSocket);
    }

    render() {
        const { joinScript } = this.state;

        return (
            <div ref={this.parent} className={`join ${joinScript ? '_init' : ''}`}>
                <div className="join__inner">
                    {this.renderHead()}
                    {this.renderContent()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: StoreT<'app'>) {
    return {
        levels: state.levels,
        windowHeight: state.windowHeight,
        isNotApp: state.isNotApp,
    };
}

export default connect(mapStateToProps)(Join);
