import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InfoPopup from './InfoPopup.jsx';
import Animate from '../../Animate.jsx';

class InfoPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { appInfoPopup, windowHeight } = this.props;

        return (
            <Animate
                className="body__infoPopup"
                isShow={appInfoPopup.isShow}
                style={{ height: `${windowHeight}px` }}
            >
                <InfoPopup {...appInfoPopup} />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
        appInfoPopup: state.appInfoPopup,
    };
}

export default connect(mapStateToProps)(InfoPopupWrapper);

InfoPopupWrapper.propTypes = {
    appInfoPopup: PropTypes.object,
    windowHeight: PropTypes.number,
};
