import React from 'react';

import Anket from '../components/Anket';
import Card from '../components/Card';
import Check from '../components/Check';
import Code from '../components/Code';
import Complete from '../components/Complete';
import Docs from '../components/Docs';
import Phone from '../components/Phone';
import Reject from '../components/Reject';
import Start from '../components/Start';

import JoinI from '../types';

const blocks = {
    start: {
        render(this: JoinI) {
            return <>{Start.call(this)}</>;
        },
    },
    phone: {
        render(this: JoinI) {
            return <>{Phone.call(this)}</>;
        },
    },
    code: {
        render(this: JoinI) {
            return <>{Code.call(this)}</>;
        },
    },
    status: {
        render(this: JoinI) {
            return <></>;
        },
    },
    anket: {
        render(this: JoinI) {
            return <>{Anket.call(this)}</>;
        },
    },
    docs: {
        render(this: JoinI) {
            return <>{Docs.call(this)}</>;
        },
    },
    card: {
        render(this: JoinI) {
            return <>{Card.call(this)}</>;
        },
    },
    check: {
        render(this: JoinI) {
            return <>{Check.call(this)}</>;
        },
    },
    reject: {
        render(this: JoinI) {
            return <>{Reject.call(this)}</>;
        },
    },
    complete: {
        render(this: JoinI) {
            return <>{Complete.call(this)}</>;
        },
    },
} as const;

export default blocks;
