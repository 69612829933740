import { Store, legacy_createStore as reduxCreateStore } from 'redux';

import { ExecutorM } from '@global/models/Executor.ts';
import { StoreT } from '@global/types';

import { DefaultPropsT, getDefaultState, getReducer } from './actions.ts';

function createAppStore(props: DefaultPropsT = {}): Store<StoreT<'app'>> {
    let user: ExecutorM | undefined;

    try {
        user = JSON.parse(localStorage.getItem('user') as string);
    } catch (error) {}

    const reducer = getReducer<'app'>({
        ...getDefaultState(props),
        user,
        isAcceptGetGeo: !!localStorage.getItem('isAcceptGetGeo'),
        appStartPopup: { isShow: false },
        appBalancePopup: { isShow: false },
        appLogoutPopup: { isShow: false },
        appGpsPopup: { isShow: false },
        appInfoPopup: { isShow: false },
        appNetworkConnectPopup: { isShow: false },
        appInstructionApplicationPopup: { isShow: false },
        appNewVersionPopup: { isShow: false },
        appGalery: { isShow: false },
        appSignContractPopup: { isShow: false },
        appSignPayPopup: { isShow: false },
        appWalletPopup: { isShow: false },
        appWebPushPopup: { isShow: false },
        appNotificationsPopup: { isShow: false },
        appFnsActivateInfoPopup: { isShow: false },
        appPayInfoPopup: { isShow: false },
        appPayCancelPopup: { isShow: false },
        appSmzInstructionPopup: { isShow: false },
        appJoinCorporationPopup: { isShow: false },
        appContractPopup: { isShow: false },
        appTaxAboutPopup: { isShow: false },
        appTaxAmountPopup: { isShow: false },
        appTownsPopup: { isShow: false },
        appCountriesPopup: { isShow: false },
        authCodePopup: { isShow: false },
        gpsDisabled: !!localStorage.getItem('gpsDisabled'),
    });

    return reduxCreateStore(reducer);
}

const store = createAppStore();

export default store;
