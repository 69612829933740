import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerPopup from '../../../functions/app/handlerPopup';

import AppInstruction from '../../AppInstruction.jsx';
import Button from '../../Button.jsx';
import Icon from '../../Icon.jsx';
import Popup from './Popup.jsx';

class InstructionApplication extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appInstructionApplicationPopup', isShow: false });

        localStorage.setItem('showInstructionApplication', true);
    }

    render() {
        return (
            <div ref={this.parent} className="appPopup _col _application">
                <div className="appPopup__inner">
                    <i className="appPopup__icon">
                        <Icon name="app-logo" />
                    </i>
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__title">
                                Добавьте наше приложение на экран вашего смартфона:
                            </div>
                            <p className="appPopup__description">
                                Вы получите быстрый доступ ко всему необходимому функционалу
                            </p>

                            <AppInstruction />
                            <div className="appPopup__button _margin">
                                <Button
                                    onClick={() => {
                                        this.hide();
                                    }}
                                >
                                    Понятно
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(InstructionApplication);

InstructionApplication.propTypes = {
    windowHeight: PropTypes.number,
    hideCallback: PropTypes.func,
};
