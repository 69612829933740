import axios from 'axios';

import changePage from '../changePage';
import getHeaders from '../getHeaders';
import checkAuth from './checkAuth';

export default function changeCorporation({ id, toPageIndex = true }) {
    return new Promise((resolve) => {
        axios
            .patch(
                `${process.env.REACT_APP_API}/executor-app`,
                { id, action: 'change-corporation' },
                { headers: getHeaders() },
            )
            .then((res) => {
                const { success } = res.data;

                if (success) {
                    localStorage.removeItem('afterReg');

                    checkAuth().then(
                        () => {
                            if (toPageIndex) {
                                changePage({ href: '' });
                            }

                            document.dispatchEvent(new CustomEvent('changeCorporation'));

                            resolve();
                        },
                        () => {
                            resolve();
                        },
                    );
                } else {
                    this.handlerLoading(null);
                }
            }, resolve);
    });
}
