import React from 'react';

import JoinSmzInstruction from '@components/JoinSmzInstruction.jsx';

import { BlockT } from '../types.ts';

const StatusSmzInstruction: BlockT = function () {
    return (
        <>
            <div className="join__blockInner _col">
                <JoinSmzInstruction />
                {this.renderButton({
                    name: 'status',
                    text: 'Всё получилось, продолжить',
                    onClick: () => {
                        this.setStep('status', 'statusSmzAccess');
                    },
                })}
            </div>
        </>
    );
};

export default StatusSmzInstruction;
