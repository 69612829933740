import React from 'react';
import PropTypes from 'prop-types';

import Animate from '../Animate.jsx';
import Loader from '../Loader.jsx';

class LinkBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { className = '', showLoader, children, handler } = this.props;

        return (
            <div
                className={`link ${className} ${showLoader ? '_isLoading' : ''}`}
                onClick={() => {
                    if (!showLoader) {
                        handler();
                    }
                }}
            >
                <Animate className="link__loader _loader" isShow={showLoader}>
                    <div className="link__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <div className="link__inner">{children}</div>
            </div>
        );
    }
}

export default LinkBtn;

LinkBtn.propTypes = {
    className: PropTypes.string,
    showLoader: PropTypes.bool,
    children: PropTypes.node,
    handler: PropTypes.func,
};
