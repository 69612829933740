const smzTabs = {
    phone: {
        content: 'Номер телефона',
    },
    inn: {
        content: 'ИНН',
    },
} as const;

export default smzTabs;
