import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import formatCardNumber from '@functions/formatCardNumber';

import Animate from '../../../components/Animate.jsx';
import AnimateChangeUp from '../../../components/AnimateChangeUp.jsx';
import Button from '../../../components/Button.jsx';
import Icon from '../../../components/Icon.jsx';

import checkAuth from '../../../functions/app/checkAuth';
import handlerPopup from '../../../functions/app/handlerPopup';
import getHeaders from '../../../functions/getHeaders';

class Wallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderCard = this.renderCard.bind(this);
        this.setCurrent = this.setCurrent.bind(this);
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    infos = {
        account: {
            title: 'Реквизиты',
            icon: 'app-wallet-account',
        },
        card: {
            title: 'Карта',
            icon: 'app-wallet-card',
        },
    };

    renderCard({ type, ...props }) {
        const { loadingKey } = this.state;
        const { user } = this.props;
        const info = this.infos[type];
        const isCurrent = user.currentWallet === props._id;

        return (
            <div className={`settings__wallet ${isCurrent ? '_current' : ''}`}>
                <div className="settings__walletHead _row">
                    <Animate className="settings__walletHeadCurrent" isShow={isCurrent}>
                        Основной способ оплаты
                    </Animate>
                    <i className="settings__walletHeadIcon">
                        <Icon name={info.icon} />
                    </i>
                    <div className="settings__walletHeadTitle">{info.title}</div>
                    <div
                        className="settings__walletHeadLink _click"
                        onClick={() => {
                            handlerPopup({ name: 'appWalletPopup', isShow: true, id: props._id });
                        }}
                    >
                        Изменить
                    </div>
                </div>
                <div className="settings__walletContent">
                    <div className="settings__walletDetails">
                        {type === 'card' ? (
                            <>
                                <div className="settings__walletDetailsItem">
                                    <span>Номер карты:</span>{' '}
                                    {formatCardNumber(props.number) || '–'}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="settings__walletDetailsItem">
                                    <span>БИК:</span> {props.bic || '–'}
                                </div>
                                <div className="settings__walletDetailsItem">
                                    <span>Банк:</span> {props.bankName || '–'}
                                </div>
                                <div className="settings__walletDetailsItem">
                                    <span>Номер счёта:</span>{' '}
                                    {formatCardNumber(props.number) || '–'}
                                </div>
                                <div className="settings__walletDetailsItem">
                                    <span>Получатель:</span> {props.addressatName || '–'}
                                </div>
                            </>
                        )}
                    </div>
                    <AnimateChangeUp
                        className="settings__walletButton"
                        renderKey={!isCurrent ? 'current' : undefined}
                        parentStyles={['height']}
                    >
                        {isCurrent ? (
                            <></>
                        ) : (
                            <>
                                <Button
                                    onClick={() => {
                                        this.setCurrent({ id: props._id });
                                    }}
                                    showLoader={loadingKey === 'setCurrent'}
                                >
                                    Сделать основным
                                </Button>
                            </>
                        )}
                    </AnimateChangeUp>
                </div>
            </div>
        );
    }

    setCurrent({ id }) {
        this.handlerLoading('setCurrent').then(() => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/executor-app`,
                    {
                        action: 'change-wallet',
                        walletId: id,
                        fields: {
                            current: true,
                        },
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        this.handlerLoading(null);

                        const { success } = res.data;

                        if (success) {
                            checkAuth(false);
                        }
                    },
                    () => {
                        this.handlerLoading(null);
                    },
                );
        });
    }

    render() {
        const { user } = this.props;

        return <div className="settings _col">{user.wallets.map(this.renderCard)}</div>;
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        pagesStore: state.pages,
    };
}

export default connect(mapStateToProps)(Wallet);

Wallet.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    pagesStore: PropTypes.object,
};
