import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '../../components/Animate.jsx';
import Pages from '../../components/Pages.jsx';
import Back from '../../components/app/Back.jsx';
import HeadPage from '../../components/app/HeadPage.jsx';
import LinkBtn from '../../components/app/LinkBtn.jsx';

import changePage from '../../functions/changePage';
import getCurrentPage from '../../functions/getCurrentPage';
import getPageLink from '../../functions/getPageLink';

import createChat from '../../requests/createChat';
import Index from './chat/Index.jsx';
import Inner from './chat/Inner.jsx';

class Chat extends HeadPage {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerBack = this.handlerBack.bind(this);
        this.createChat = this.createChat.bind(this);
    }

    titles = {
        'chat-index': {
            render() {
                return <>Поддержка</>;
            },
        },
        'chat-inner': {
            render() {
                return <>Чат поддержки</>;
            },
        },
    };

    getCurrentPage() {
        const { pagesStore } = this.props;
        const currentPage = getCurrentPage({
            pages: pagesStore,
            filter: (page) => page.parentName === 'chat',
        });

        return currentPage;
    }

    handlerBack() {
        const { pagesOrder } = this.props;

        if (
            pagesOrder &&
            pagesOrder[pagesOrder.length - 2] &&
            pagesOrder[pagesOrder.length - 2] !== 'chat-inner'
        ) {
            window.history.back();
        } else {
            const currentPage = this.getCurrentPage();

            let href = 'index';

            if (currentPage === 'chat-inner') {
                href = 'chat-index';
            }

            changePage({ href: getPageLink({ name: href }) });
        }
    }

    pages = {
        'chat-index': {
            render() {
                return (
                    <>
                        <Index
                            getScrollParent={() =>
                                this.parent.current?.querySelector(
                                    '.headPage__innerBox._chat-index',
                                )
                            }
                        />
                    </>
                );
            },
        },
        'chat-inner': {
            render() {
                const { updateHeightKey } = this.state;

                return (
                    <>
                        <Inner updateHeightKey={updateHeightKey} />
                    </>
                );
            },
        },
    };

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    createChat() {
        this.handlerLoadingKey('create').then(() => {
            createChat({ changeToChatPage: false, isNotNotificate: true }).then(
                ({ id }) => {
                    changePage({ href: getPageLink({ name: 'chat-inner', ids: { 1: id } }) });

                    this.handlerLoadingKey(null);
                },
                () => null,
            );
        });
    }

    render() {
        const { loadingKey } = this.state;

        return (
            <div ref={this.parent} className="headPage">
                <div className="headPage__head">
                    <div className="pageHead _col">
                        <div className="pageHead__top _row _notMargin">
                            <div className="pageHead__back">
                                <Back handler={this.handlerBack} />
                            </div>
                            <div className="pageHead__titles">
                                <Pages
                                    classNamePage="pageHead__title"
                                    filter={(page) => page.parentName === 'chat'}
                                    pages={this.titles}
                                    context={this}
                                    fullHeight={false}
                                />
                            </div>
                            <Animate
                                className="pageHead__link _dynamic"
                                isShow={this.getCurrentPage() !== 'chat-inner'}
                            >
                                <LinkBtn
                                    showLoader={loadingKey === 'create'}
                                    handler={this.createChat}
                                >
                                    + Чат
                                </LinkBtn>
                            </Animate>
                        </div>
                    </div>
                </div>
                <div className="headPage__inner _pages">
                    <Pages
                        classNamePage="headPage__innerBox"
                        filter={(page) => page.parentName === 'chat'}
                        getClassName={() => '_notPadding'}
                        pages={this.pages}
                        context={this}
                        fullHeight={false}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        pagesStore: state.pages,
        counterChangePage: state.counterChangePage,
        pagesOrder: state.pagesOrder,
    };
}

export default connect(mapStateToProps)(Chat);

Chat.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    pagesStore: PropTypes.object,
    counterChangePage: PropTypes.number,
    pagesOrder: PropTypes.array,
};
