import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../../components/Icon.jsx';
import Link from '../../../components/Link.jsx';

import logout from '../../../functions/app/logout';
import LinkBtn from '../../../components/app/LinkBtn.jsx';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.logout = this.logout.bind(this);
    }

    links = {
        profile: {
            icon: 'app-settings-profile',
            content: 'Данные аккаунта',
            href: 'settings-profile',
        },
        legal: {
            icon: 'app-settings-legal',
            content: 'О приложении',
            href: 'settings-legal',
        },
        fns: {
            image: 'fns-icon.png',
            content: 'Мой налог',
            href: 'settings-fns',
        },
        wallet: {
            icon: 'app-settings-wallet',
            content: 'Реквизиты',
            href: 'settings-wallet',
        },
        tax: {
            icon: 'app-settings-fns-tax',
            content: 'Налоговая копилка',
            href: 'settings-tax',
        },
    };

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    getPages() {
        const { user } = this.state;
        const pages = ['profile', 'wallet'];

        if (user?.organization === 'SMZ') {
            pages.push(...['fns', 'tax']);
        }

        pages.push('legal');

        return pages;
    }

    logout() {
        const { loadingKey } = this.state;

        if (!loadingKey) {
            this.handlerLoadingKey('logout').then(() => {
                logout().then(
                    () => {
                        this.handlerLoadingKey(null);
                    },
                    () => null,
                );
            });
        }
    }

    componentDidMount() {
        this.setState({ user: this.props.user });
    }

    render() {
        const { loadingKey } = this.state;

        return (
            <div className="settings _col">
                <div className="settings__blocks">
                    <div className="settings__block">
                        {this.getPages().map((name) => {
                            const link = this.links[name];

                            return (
                                <Link
                                    className="settings__blockLink _row"
                                    key={name}
                                    pageName={link.href}
                                >
                                    {link.image ? (
                                        <img
                                            src={require(`../../../img/app/${link.image}`)}
                                            alt=""
                                            className="settings__blockLinkIcon"
                                        />
                                    ) : (
                                        <i className="settings__blockLinkIcon">
                                            <Icon name={link.icon} />
                                        </i>
                                    )}

                                    <div className="settings__blockLinkContent">{link.content}</div>

                                    <i className="settings__blockLinkArrow">
                                        <Icon name="arrow-next-2" />
                                    </i>
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div className="settings__link">
                    <LinkBtn
                        className="_alert"
                        showLoader={loadingKey === 'logout'}
                        handler={this.logout}
                    >
                        Выйти из аккаунта
                    </LinkBtn>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        pagesStore: state.pages,
    };
}

export default connect(mapStateToProps)(Index);

Index.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    pagesStore: PropTypes.object,
};
