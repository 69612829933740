import React from 'react';

import I from '../types.ts';

const renderBlock: I['renderBlock'] = function ({ prop }) {
    const block =
        this.statusBlocks[prop as keyof typeof this.statusBlocks] ||
        this.blocks[prop as keyof typeof this.blocks];

    return <div className={`join__block _COL _NOSCROLL _${prop}`}>{block && block.render.call(this)}</div>;
};

export default renderBlock;
