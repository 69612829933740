import { getCookie } from '@functions/handlerCookies.js';

import I from '../types.ts';

const getHeaders: I['getHeaders'] = function () {
    return {
        headers: {
            ...(getCookie('joinToken') ? { joinToken: getCookie('joinToken') } : {}),
        },
    };
};

export default getHeaders;
