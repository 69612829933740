import axios from 'axios';

import handlerLoading from '@functions/handlerLoading.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../../types.ts';

const statusIpHandler = async function (this: I) {
    const { model } = this.state;
    let response;

    await handlerLoading.call(this, 'status-ip-form');

    try {
        response = await axios.patch<
            {},
            { data: ReqResponseT<{ joinInfo?: any; executorInfo?: any }> }
        >(
            `${process.env.REACT_APP_API}/join`,
            {
                fields: { inn: model!.inn },
                checkOrganization: true,
            },
            this.getHeaders(),
        );
    } catch (error) {
        return;
    }

    const { success, data } = response.data;

    if (success) {
        const { joinInfo, executorInfo } = data;

        if (executorInfo) {
            await this.init({ fields: this.getInitFields(executorInfo) });
        }

        if (joinInfo) {
            const statusData = this.checkCurrentStep(joinInfo);

            this.setStep(statusData.status, statusData.currentStatusStep);
        }
    } else {
        const { error } = data;

        this.setState({ error });
    }

    await handlerLoading.call(this, undefined);
};

export default statusIpHandler;
