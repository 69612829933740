import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import setTheme from '../../functions/setTheme';

import { dispatcher } from '../../redux/redux';
import Animate from '../Animate.jsx';

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    checkReady() {
        const { windowIsLoad } = this.props;

        if (windowIsLoad && !this.windowIsLoad) {
            this.windowIsLoad = true;

            setTimeout(() => {
                this.setState({ isInit: true }, () => {
                    dispatcher({ type: 'isHideStartPopup', data: true }).then(() => {
                        setTheme();
                    });
                });
            }, 2_000);
        }
    }

    componentDidMount() {
        this.checkReady();
    }

    componentDidUpdate() {
        this.checkReady();
    }

    render() {
        const { isInit } = this.state;
        const { windowHeight } = this.props;

        return (
            <Animate
                className="body__start"
                style={{ height: `${windowHeight}px` }}
                isShow={!isInit}
            >
                <div className="startPopup _col">
                    <div className="startPopup__head" />
                    <div className="startPopup__content _col">
                        <img
                            src={require('../../img/crm/logo-center.svg').default}
                            alt=""
                            className="startPopup__logo"
                        />
                        <div className="startPopup__title">CRM LIVECARGO</div>
                        <p className="startPopup__description">
                            Цифровизация вашего бизнеса:
                            <br />
                            от найма — до выплат
                        </p>
                    </div>
                    <div className="startPopup__foot">Загружаем данные…</div>
                </div>
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
        windowIsLoad: state.windowIsLoad,
    };
}

export default connect(mapStateToProps)(Start);

Start.propTypes = {
    windowHeight: PropTypes.number,
    windowIsLoad: PropTypes.bool,
};
