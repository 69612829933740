import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import Icon from '../../Icon.jsx';

class NetworkDisconnect extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
        this.checkAgain = this.checkAgain.bind(this);
    }

    checkHideDisabled() {
        return true;
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appNetworkConnectPopup', isShow: false });

        localStorage.setItem('appNetworkConnectPopup', true);
    }

    checkAgainTimerId;

    checkAgain() {
        if (this.checkAgainTimerId) {
            clearTimeout(this.checkAgainTimerId);
        }

        this.setState({ isCheckAgain: true });

        this.checkAgainTimerId = setTimeout(() => {
            this.setState({ isCheckAgain: false });
        }, 3_000);
    }

    render() {
        const { isCheckAgain } = this.state;

        return (
            <div ref={this.parent} className="appPopup _col _networkDisconnect">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <i className="appPopup__icon">
                                <Icon name="app-network-disconnect" />
                            </i>
                            <div className="appPopup__title">
                                Нет подключения
                                <br />к интернету
                            </div>
                            <p className="appPopup__description">
                                Проверьте ваше интернет-соединение,
                                <br />
                                перезагрузите сотовые данные
                                <br />и нажмите кнопку ниже
                            </p>
                            <div className="appPopup__button">
                                <Button
                                    isDisabled={false}
                                    onClick={this.checkAgain}
                                    showLoader={!!isCheckAgain}
                                >
                                    Попробовать ещё раз
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(NetworkDisconnect);

NetworkDisconnect.propTypes = {
    windowHeight: PropTypes.number,
    hideCallback: PropTypes.func,
};
