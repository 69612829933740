import React from 'react';

import BorderDashed from '@components/BorderDashed.jsx';
import Animate from '@components/animate/Animate.tsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import I from '../types.ts';

const renderDoc: I['renderDoc'] = function (doc) {
    const { loadingKey } = this.state;
    const uploadedFilesCounter = doc.files.filter((file) => file.file.src).length;

    return (
        <div className="join__blockField _file">
            <div className="join__blockFieldBox">
                <div className="join__blockDoc">
                    <div className="join__blockDocHead _ROW">
                        {doc.name}
                        <AnimateChange
                            className="join__blockDocCounter"
                            renderKey={uploadedFilesCounter}
                        >
                            <>
                                {uploadedFilesCounter}/{doc.files.length}
                            </>
                        </AnimateChange>
                    </div>
                    <div className="join__blockDocContent _row">
                        {doc.files.map((file) => {
                            const fileName = file.file.name;
                            const isUploadedFile = !!file.file.src;
                            const isLoading = loadingKey === `file.${doc._id}.${file._id}`;
                            const isError = false;

                            return (
                                <label
                                    className={`join__blockDocFile _col _click ${
                                        doc.files.length === 1 ? '_full' : ''
                                    } ${isLoading ? '_loading' : ''} ${
                                        isUploadedFile ? '_isUpload' : ''
                                    } ${isError ? '_error' : ''}`}
                                    key={file._id}
                                >
                                    <div className="join__blockDocFileBack">
                                        <BorderDashed className="_upload" />
                                    </div>
                                    <input
                                        type="file"
                                        className="join__blockDocFileInput"
                                        onChange={this.uploadFile.bind(this, {
                                            id: doc._id,
                                            fileId: file._id,
                                        })}
                                        disabled={!!loadingKey}
                                    />
                                    <div className="join__blockDocFilePreview _col">
                                        <i className="join__blockDocFilePreviewIcon">
                                            <Icon name="add" />
                                        </i>
                                        <p className="join__blockDocFilePreviewDescription">
                                            Загрузите
                                            <br />
                                            файл
                                        </p>
                                    </div>
                                    <LoaderBlock
                                        className="join__blockDocFileLoader"
                                        isShow={isLoading}
                                        loaderClassName="_main"
                                    />
                                    <Animate
                                        className="join__blockDocFileInfo _col"
                                        isShow={isUploadedFile}
                                    >
                                        <i className="join__blockDocFileInfoIcon">
                                            <Icon name="file-type-doc" />
                                        </i>
                                        <div className="join__blockDocFileInfoName">{fileName}</div>
                                    </Animate>
                                </label>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default renderDoc;
