import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';

import Icon from '../../Icon.jsx';
import checkWebPush from '../../../functions/checkWebPush';

class WebPush extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
        this.startWebPush = this.startWebPush.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appWebPushPopup', isShow: false });

        localStorage.setItem('appWebPushPopup', true);
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    async startWebPush() {
        await this.handlerLoading('loading');

        try {
            await checkWebPush({});

            this.hide();

            this.handlerLoading(null);
        } catch (error) {
            this.hide();

            this.handlerLoading(null);
        }
    }

    render() {
        const { loadingKey } = this.state;

        return (
            <div ref={this.parent} className="appPopup _col _webPush">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <i className="appPopup__icon">
                                <Icon name="app-main-notification" />
                            </i>
                            <div className="appPopup__title">Уведомления в&nbsp;приложении</div>
                            <p className="appPopup__description">
                                Предоставьте доступ к пуш-уведомлениям приложения, чтобы быстро
                                получать важную информацию.
                            </p>
                            <div className="appPopup__button">
                                <Button
                                    icon={{ type: 'end', name: 'arrow-next-2' }}
                                    showLoader={loadingKey === 'loading'}
                                    onClick={this.startWebPush}
                                >
                                    Предоставить доступ
                                </Button>
                            </div>
                            <div className="appPopup__link">
                                <div className="link _click _alert" onClick={this.hide}>
                                    Не предоставлять
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(WebPush);

WebPush.propTypes = {
    windowHeight: PropTypes.number,
    hideCallback: PropTypes.func,
};
