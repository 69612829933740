import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import Icon from '../../Icon.jsx';

class ServerNotAvailable extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'serverNotAvailablePopup', isShow: false });
    }

    render() {
        return (
            <div ref={this.parent} className="appPopup _col _serverNotAvailable">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <i className="appPopup__icon">
                                <Icon name="alert-2" />
                            </i>
                            <div className="appPopup__title">Идут технические работы</div>
                            <p className="appPopup__description">
                                Ввиду избежания ошибок, функции <b>проведения платежей</b> и{' '}
                                <b>подписания договоров</b> не работают. Приносим извинения за
                                неудобства
                            </p>
                            <div className="appPopup__button">
                                <Button onClick={this.hide}>Понятно</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(ServerNotAvailable);

ServerNotAvailable.propTypes = {
    windowHeight: PropTypes.number,
    hideCallback: PropTypes.func,
};
