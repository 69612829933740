import checkAuth from '@functions/app/checkAuth.js';
import changePage from '@functions/changePage.ts';
import { deleteCookie, setCookie } from '@functions/handlerCookies.js';
import handlerLoading from '@functions/handlerLoading.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { dispatcher } from '@redux/redux.ts';

import I from '../types.ts';

const login: I['login'] = async function ({ id, hash }) {
    setCookie(process.env.REACT_APP_HASH, hash);

    deleteCookie('joinToken');

    await dispatcher({ type: 'isProccessLogin', data: true });
    await handlerLoading.call(this, 'to-cabinet');
    await checkAuth(true, { id });
    await handlerLoading.call(this, 'to-cabinet');
    await changePage<'app'>({ pageName: 'index' });

    if (this.props.isNotApp && !localStorage.getItem('showInstructionApplication')) {
        setTimeout(() => {
            handlerPopup<'app'>('appInstructionApplicationPopup', {
                isShow: true,
            });
        }, 3_000);
    }

    localStorage.removeItem('joinAuthHash');
    localStorage.removeItem('joinAuthId');

    await dispatcher({ type: 'isProccessLogin', data: false });
    await handlerLoading.call(this, undefined);
};

export default login;
