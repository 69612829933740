import axios from 'axios';
import changePage from '../changePage';
import getHeaders from '../getHeaders';
import getPageLink from '../getPageLink';

export default function createChat({ id, idOfOrder }) {
    return new Promise((resolve) => {
        if (id) {
            changePage({ href: getPageLink({ name: 'order-chat-inner', ids: { 1: id } }) });

            resolve(id);
        } else {
            axios
                .post(`${process.env.REACT_APP_API}/chat`, { idOfOrder }, { headers: getHeaders() })
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { id: newId } = data;

                            changePage({
                                href: getPageLink({ name: 'order-chat-inner', ids: { 1: newId } }),
                            });

                            resolve(newId);
                        } else {
                            const { id: alreadyId } = data;

                            changePage({
                                href: getPageLink({
                                    name: 'order-chat-inner',
                                    ids: { 1: alreadyId },
                                }),
                            });

                            resolve(alreadyId);
                        }
                    },
                    () => null,
                );
        }
    });
}