export default function getOrderTimeType({ order }) {
    if (!['choice-crew', 'in-proccess'].includes(order.status)) {
        return null;
    }

    let timeType = 'wait';
    const date = new Date();
    const orderDate = new Date(order.dateOfOrder);

    if (orderDate.getTime() - date.getTime() < 1_000 * 3600 * 2) {
        timeType = 'fast';
    }

    return timeType;
}
