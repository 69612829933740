import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Back from '../../components/app/Back.jsx';
import HeadPage from '../../components/app/HeadPage.jsx';

import changePage from '../../functions/changePage';
import getCurrentPage from '../../functions/getCurrentPage';
import getPageLink from '../../functions/getPageLink';

import Inner from './corportaions/Inner.jsx';

class Corporations extends HeadPage {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderPage = this.renderPage.bind(this);
    }

    tabsOrder = ['main', 'free'];

    tabs = {
        main: {
            content: 'Подключённые',
            href: 'corporations-main',
        },
        free: {
            content: 'Доступные',
            href: 'corporations-free',
        },
    };

    changeTab(name) {
        const tab = this.tabs[name];
        const { href } = tab;

        changePage({ href: getPageLink({ name: href }) });
    }

    checkCurrentTab(name) {
        const { pagesStore } = this.props;
        const currentPage = getCurrentPage({
            page: pagesStore,
            filter: (page) => page.parentName === 'corporations',
        });
        const tab = this.tabs[name];
        const { href } = tab;

        if (currentPage) {
            this.currentPage = currentPage;
        }

        return this.currentPage === href;
    }

    // pagesOrder = ['corporations-main', 'corporations-free'];

    // pages = {
    //     'corporations-main': {
    //         render() {
    //             return (
    //                 <>
    //                     <Inner name="main" />
    //                 </>
    //             );
    //         },
    //     },
    //     'corporations-free': {
    //         render() {
    //             return (
    //                 <>
    //                     <Inner name="free" />
    //                 </>
    //             );
    //         },
    //     },
    // };

    getPages() {
        const { pagesStore } = this.props;
        const currentPage = getCurrentPage({
            page: pagesStore,
            filter: (page) => page.parentName === 'corporations',
        });

        if (currentPage) {
            this.currentPage = currentPage;
        }

        return [{ name: this.currentPage }];
    }

    renderPage({ prop: name }) {
        const page = this.pages[name];

        return <div className="orders__page">{page.render.call(this)}</div>;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    render() {
        // const { pagesStore } = this.props;
        // const currentPage = getCurrentPage({
        //     page: pagesStore,
        //     filter: (page) => page.parentName === 'corporations',
        // });

        return (
            <div ref={this.parent} className="headPage">
                <div className="headPage__head">
                    <div className="pageHead _col">
                        <div className="pageHead__top _row _notMargin">
                            <div className="pageHead__back">
                                <Back
                                    handler={() => {
                                        changePage({ href: '' });
                                    }}
                                />
                            </div>
                            <div className="pageHead__title">Компании</div>
                        </div>
                    </div>
                </div>
                <div className="headPage__inner">
                    <div className="orders">
                        <Inner name="main" />
                        {/* <ListAbsoluteMain
                            className="orders__pages"
                            items={this.getPages()}
                            renderItem={this.renderPage}
                            classNameItem="orders__page"
                            prop="name"
                            paramsParent={{ width: true }}
                            styles={[]}
                            isNotParamsItem={true}
                            currentItemKey={currentPage}
                            allItems={this.pagesOrder}
                        /> */}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        pagesStore: state.pages,
    };
}

export default connect(mapStateToProps)(Corporations);

Corporations.propTypes = {
    user: PropTypes.object,
    pagesStore: PropTypes.object,
};
