export default function getStatus({ counterNotRead, isClose }) {
    if (isClose === true) {
        return 'complete';
    }

    if (counterNotRead > 0) {
        return 'important';
    }

    return 'proccess';
}
