import I from '../types.ts';

const getInitFields: I['getInitFields'] = function (info) {
    const resultInfo = {
        ...info,
    };

    if (info) {
        if (info.phone) {
            resultInfo['smz-phone'] = info.phone;
        }

        if (info.inn) {
            resultInfo['smz-inn'] = info.inn;
        }
    }

    return resultInfo;
};

export default getInitFields;
