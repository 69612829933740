import axios from 'axios';

import connectSockets from '@functions/connectSockets.js';
import { deleteCookie, getCookie } from '@functions/handlerCookies.js';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const getInfo: I['getInfo'] = async function (start) {
    const { levels } = this.props;
    const link = levels[1];

    if (link) {
        let response;

        try {
            response = await axios.get<
                {},
                {
                    data: ReqResponseT<{
                        joinScript?: I['state']['joinScript'];
                        executorInfo?: Record<any, unknown>;
                        joinInfo?: {
                            status: I['state']['currentStep'];
                        };
                    }>;
                }
            >(`${process.env.REACT_APP_API}/join?scriptLink=${link}`, this.getHeaders());
        } catch (error) {
            return Promise.reject(error);
        }

        const { success, data } = response.data;

        if (success) {
            const { joinScript, executorInfo, joinInfo } = data;

            await this.setAsyncState({ joinScript });

            const statusData = this.checkCurrentStep(joinInfo);
            const currentWalletType =
                joinScript && ['IP', 'SMZ'].includes(joinScript.executorOrganization)
                    ? 'account'
                    : 'card';

            if (!joinInfo) {
                statusData.status = 'start';
                statusData.currentStatusStep = 'statusStart';
            }

            if (!joinInfo) {
                deleteCookie('joinToken');
            }

            if (start !== false) {
                await this.init({ fields: this.getInitFields(executorInfo) });
            }

            if (getCookie('joinToken')) {
                connectSockets();
            }

            const rejectComment = executorInfo?.rejectComment as string;

            this.setState(
                {
                    currentStep: statusData.status,
                    currentStatusStep: statusData.currentStatusStep,
                    rejectComment,
                    currentWalletType,
                },
                this.checkTabsPosition.bind(this),
            );

            return;
        }

        this.setState({ isError: true });
    }
};

export default getInfo;
