import heic2any from 'heic2any';

export default async function convertHEICFile(file: File): Promise<{ result: Blob; url?: string }> {
    if (file.type !== 'image/heic') {
        return { result: file };
    }

    try {
        const blobURL = URL.createObjectURL(file);

        const blobRes = await fetch(blobURL);
        const blob = await blobRes.blob();

        const result = (await heic2any({ blob })) as Blob;

        const url = URL.createObjectURL(result);

        return { result, url };
    } catch (error) {
        return { result: file };
    }
}
