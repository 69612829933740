import axios from 'axios';
import { dispatcher, store } from '../../redux/redux';
import getHeaders from '../getHeaders';
import checkAuth from './checkAuth';
import changePage from '../changePage';

export default function handlerTax(type, outside) {
    const user = JSON.parse(JSON.stringify(store.getState().user));

    this.setState({ taxError: null });

    return new Promise((resolve) => {
        this.handlerLoading(type).then(() => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/executor`,
                    { action: 'handler-tax', type },
                    { headers: getHeaders() },
                )
                .then((res) => {
                    const { success, data } = res.data;

                    if (success) {
                        Object.keys(data).forEach((prop) => {
                            user[prop] = data[prop];
                        });

                        dispatcher({ type: 'user', data: user });

                        this.handlerLoading(null);

                        if (outside) {
                            changePage({ href: 'settings/tax' });
                        }
                    } else {
                        const { message } = data;

                        if (message === 'Tax not confirm') {
                            this.setState({ taxError: 'not-confirm' });

                            this.handlerLoading(null);
                        } else if (message === 'Account not adding for company') {
                            checkAuth().then(() => {
                                changePage({ href: 'settings/fns' });

                                this.handlerLoading(null);
                            });
                        }
                    }

                    resolve();
                });
        });
    });
}
