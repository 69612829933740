import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import handlerPopup from '../../../functions/app/handlerPopup';

import Field from '../../Field.jsx';
import Button from '../../Button.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';

class AuthCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.changePhone = this.changePhone.bind(this);
        this.handlerCodePopup = this.handlerCodePopup.bind(this);
    }

    getErrors() {
        const { code } = this.props;

        return code?.error ? [{ name: code.error, content: code.error }] : [];
    }

    renderError({ item: error }) {
        return <div className="auth__error">{error?.content}</div>;
    }

    changePhone() {
        const { handlerBlock } = this.props;

        handlerBlock('phone');
    }

    handlerCodePopup() {
        const { phone } = this.state;
        const { checkPhone } = this.props;

        handlerPopup({
            name: 'authCodePopup',
            isShow: true,
            phone,
            changePhone: this.changePhone,
            checkPhone,
        });
    }

    componentDidMount() {
        const { phone } = this.props;

        this.setState({ phone: phone?.value });
    }

    render() {
        const { phone } = this.state;
        const { code, login, handlerField, loadingKey } = this.props;

        return (
            <div className="auth__blockInner _col">
                <img src={require('../../../img/emodzi/cloud.png')} alt="" className="auth__icon" />
                <div className="auth__title">Введите код из SMS</div>
                <div className="auth__description">
                    На номер {phone}
                    <br />
                    отправлено <b>сообщение с кодом</b>
                </div>
                <div className="auth__field">
                    <Field
                        type="app"
                        keyName="code"
                        name="code"
                        value={code?.value}
                        error={code?.error}
                        callback={handlerField}
                    />
                </div>
                <ListAbsoluteMain
                    className="auth__errors"
                    items={this.getErrors()}
                    renderItem={this.renderError}
                    classNameItem="auth__error"
                    prop="name"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    isNotParamsItem={true}
                />
                <div className="auth__button">
                    <Button
                        icon={{ type: 'end', name: 'arrow-next-2' }}
                        showLoader={loadingKey === 'code'}
                        onClick={login}
                    >
                        Войти
                    </Button>
                </div>
                <div className="auth__link">
                    <div className="link _click" onClick={this.handlerCodePopup}>
                        Не пришёл код?
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(AuthCode);

AuthCode.propTypes = {
    user: PropTypes.object,
    phone: PropTypes.object,
    code: PropTypes.object,
    login: PropTypes.func,
    handlerField: PropTypes.func,
    loadingKey: PropTypes.string,
    handlerBlock: PropTypes.func,
    checkPhone: PropTypes.func,
};
