import React from 'react';

import Icon from '@components/icon/Icon.tsx';

import { BlockT } from '../types.ts';

const StatusStart: BlockT = function () {
    const { currentOrganization } = this.state;
    const joinScript = this.state.joinScript!;
    const statusInfo = this.statuses[joinScript.executorOrganization];

    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__blockTitle">
                    Выберите ваш
                    <br />
                    текущий статус
                </div>
                <div className="join__blockText">
                    Выберите статус, в котором
                    <br />
                    вы будете работать с заказчиком
                </div>
                <div className="join__statuses">
                    <label className={`join__status`}>
                        <input
                            type="checkbox"
                            className="join__statusInput"
                            checked={currentOrganization === joinScript.executorOrganization}
                            onChange={() => {
                                this.setState({
                                    currentOrganization:
                                        currentOrganization === joinScript.executorOrganization
                                            ? undefined
                                            : joinScript.executorOrganization,
                                    error: undefined,
                                });
                            }}
                        />
                        <div className="join__statusContent _click">
                            <div className="join__statusTitle">{statusInfo?.title}</div>
                            <p
                                className="join__statusDescription"
                                dangerouslySetInnerHTML={{ __html: statusInfo?.description }}
                            ></p>
                            <div className="join__statusPoint _col" />
                        </div>
                    </label>
                    {joinScript?.executorOrganization === 'SMZ' && (
                        <>
                            <div className="join__status _link">
                                <div
                                    className="join__statusContent _click"
                                    onClick={() => {
                                        this.setStep('status', 'statusSmzInstruction');
                                    }}
                                >
                                    <div className="join__statusTitle">
                                        Я хочу стать самозанятым
                                    </div>
                                    <p className="join__statusDescription">
                                        Будет пройдена регистрация в качестве самозанятого. Займет
                                        10 минут.
                                    </p>
                                    <i className="join__statusArrow">
                                        <Icon name="arrow-next" />
                                    </i>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {this.renderError()}
                {this.renderButton({ name: 'status', text: 'Продолжить' })}
            </div>
        </>
    );
};

export default StatusStart;
