import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Link from '../../../../components/Link.jsx';
import Icon from '../../../../components/Icon.jsx';

import getArticles from '../../../../requests/getArticles';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

class LegalIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async getList() {
        try {
            const { shortList } = await getArticles('legal', {});

            this.setState({ shortList });
        } catch (error) {
            Promise.resolve();
        }
    }

    componentDidMount() {
        this.getList();
    }

    render() {
        const { shortList } = this.state;

        return (
            <div className="settings _col">
                <Animate className="settings__loader _loader" isShow={!shortList}>
                    <div className="settings__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <div className="settings__blocks">
                    <div className={`settings__block ${!shortList ? '_hide' : ''}`}>
                        {shortList?.map((item) => {
                            const { title, url, _id } = item;

                            return (
                                <Link
                                    className="settings__blockLink _row"
                                    key={_id}
                                    pageName="settings-legal-inner"
                                    ids={{ 2: url }}
                                >
                                    <i className="settings__blockLinkIcon">
                                        <Icon name="file-type-doc" />
                                    </i>
                                    <div
                                        className="settings__blockLinkContent"
                                        dangerouslySetInnerHTML={{ __html: title }}
                                    ></div>
                                    <i className="settings__blockLinkArrow">
                                        <Icon name="arrow-next-2" />
                                    </i>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(LegalIndex);

LegalIndex.propTypes = {
    user: PropTypes.object,
};
