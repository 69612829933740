import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field from '../../Field.jsx';
import Button from '../../Button.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import handlerPopup from '../../../functions/app/handlerPopup';

class AuthPhone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getErrors() {
        const { phone } = this.props;

        return phone?.error ? [{ name: phone.error, content: phone.error }] : [];
    }

    renderError({ item: error }) {
        return <div className="auth__error">{error?.content}</div>;
    }

    render() {
        const { phone, checkPhone, handlerField, loadingKey } = this.props;

        return (
            <div className="auth__blockInner _col">
                <img
                    src={require('../../../img/emodzi/waving-hand-sign.png')}
                    alt=""
                    className="auth__icon"
                />
                <div className="auth__title">Войдите в аккаунт</div>
                <div className="auth__description">
                    Введите ваш номер телефона
                    <br />и нажмите <b>кнопку «Далее»</b>
                </div>
                <div className="auth__field">
                    <Field
                        type="app"
                        keyName="phone"
                        name="phone"
                        value={phone?.value}
                        error={phone?.error}
                        callback={handlerField}
                    />
                </div>
                <ListAbsoluteMain
                    className="auth__errors"
                    items={this.getErrors()}
                    renderItem={this.renderError}
                    classNameItem="auth__error"
                    prop="name"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    isNotParamsItem={true}
                />
                <div className="auth__button">
                    <Button
                        icon={{ type: 'end', name: 'arrow-next-2' }}
                        showLoader={loadingKey === 'phone'}
                        onClick={checkPhone}
                    >
                        Далее
                    </Button>
                </div>
                <div className="auth__policy">
                    Нажимая кнопку «Далее», вы соглашаетесь
                    <br />с{' '}
                    <span
                        className="_click"
                        onClick={() => {
                            handlerPopup({
                                name: 'appInfoPopup',
                                isShow: true,
                                popupName: 'user-agreement',
                            });
                        }}
                    >
                        Пользовательским соглашением
                    </span>
                    <br />и{' '}
                    <span
                        onClick={() => {
                            handlerPopup({
                                name: 'appInfoPopup',
                                isShow: true,
                                popupName: 'policy',
                            });
                        }}
                    >
                        Политикой обработки персональных данных
                    </span>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(AuthPhone);

AuthPhone.propTypes = {
    user: PropTypes.object,
    phone: PropTypes.object,
    checkPhone: PropTypes.func,
    handlerField: PropTypes.func,
    loadingKey: PropTypes.string,
};
