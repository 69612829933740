import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getArticles from '../../../../requests/getArticles';
import getFormatedDate from '../../../../functions/getFormatedDate';
import setSpacesInText from '../../../../functions/setSpacesInText';
import Loader from '../../../../components/Loader.jsx';
import Animate from '../../../../components/Animate.jsx';

class LegalInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async getArticle() {
        const { levels } = this.props;
        const url = levels[2];

        const { article } = await getArticles('legal', { params: [{ key: 'url', value: url }] });

        this.setState({ article });
    }

    componentDidMount() {
        this.getArticle();
    }

    render() {
        const { article } = this.state;

        return (
            <>
                <div className={`settings__legal ${article ? '_ready' : ''}`}>
                    <Animate className="settings__loader _loader" isShow={!article}>
                        <div className="settings__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div className="settings__legalInner">
                        {article && (
                            <>
                                <div className="settings__legalTitle">{article.title}</div>
                                <div className="settings__legalDate">
                                    {getFormatedDate({
                                        date: new Date(article.createdDate),
                                        type: 'textYear',
                                    })}
                                </div>
                                <div
                                    className="settings__legalContent"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(article.content),
                                    }}
                                ></div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(LegalInner);

LegalInner.propTypes = {
    levels: PropTypes.array,
};
