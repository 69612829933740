import I from '../types.ts';

import phoneHandler from './steps/phoneHandler.ts';

const checkCode: I['checkCode'] = async function (code) {
    this.setState({ error: undefined });

    try {
        await phoneHandler.call(this, code);

        return true;
    } catch (error) {
        return false;
    }
};

export default checkCode;
