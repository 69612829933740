import I from '../../types.ts';

const statusHandler = async function (this: I) {
    const { currentOrganization } = this.state;

    if (!currentOrganization) {
        this.setState({ error: { name: 'status', text: 'Необходимо выбрать статус' } });
    } else if (currentOrganization === 'SMZ') {
        this.setStep('status', 'statusSmzAccess');
    } else if (currentOrganization === 'IP') {
        this.setStep('status', 'statusIpForm');
    } else {
        await this.updateHandler({ name: 'status', nextStep: 'anket', status: 'anket' });
    }
};

export default statusHandler;
