import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';

import I from '../types.ts';

const renderError: I['renderError'] = function () {
    const { error } = this.state;

    return (
        <AnimateChange
            className="join__blockError"
            renderKey={error?.text}
            styles={['height']}
            paramsParent={{ width: true }}
            callback={this.updateBlocksKey.bind(this)}
        >
            <> {error?.text}</>
        </AnimateChange>
    );
};

export default renderError;
