import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';

import { BlockT } from '../types.ts';

const Reject: BlockT = function () {
    const { rejectComment } = this.state;

    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__blockIcon _alert">
                    <Icon name="popup-alert" />
                </div>
                <div className="join__blockTitle">
                    Ваша заявка
                    <br />
                    отправлена на доработку
                </div>
                <div
                    className="join__blockText"
                    dangerouslySetInnerHTML={{
                        __html: setSpacesInText(`Причина: ${rejectComment}`),
                    }}
                ></div>
                {this.renderButton({ text: 'Вернуться назад', name: 'reject', reverseIcon: true })}
                {this.renderTelegram()}
            </div>
        </>
    );
};

export default Reject;
