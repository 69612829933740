import React from 'react';

import Loader from '@components/loader/Loader.tsx';

import { BlockT } from '../types.ts';

const Check: BlockT = function () {
    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__blockLoader">
                    <Loader className="_main" />
                </div>
                <div className="join__blockTitle">
                    Проверяем ваши
                    <br />
                    документы
                </div>
                <div className="join__blockText">
                    В рабочее время это занимает до часа. Результаты проверки появятся на этой
                    странице.
                </div>
                {this.renderTelegram()}
            </div>
        </>
    );
};

export default Check;
