import React from 'react';

import { BlockT } from '../types.ts';

const StatusSmzAccess: BlockT = function () {
    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__fnsLogo _col">
                    <img
                        src={require('../../../../img/fns-logo.svg').default}
                        alt=""
                        className="join__fnsLogoIcon"
                    />
                </div>
                <div className="join__blockTitle">
                    Разрешите доступ
                    <br />к «Мой налог»
                </div>
                <div className="join__blockText _marginBottom">
                    Доступ нужен, чтобы мы могли декларировать ваш доход — считать и&nbsp;оплачивать
                    налоги за вас.
                </div>
                {this.renderButton({
                    name: 'status',
                    text: 'Разрешить доступ',
                    onClick: () => {
                        this.setStep('status', 'statusSmzForm');
                    },
                })}
                <div className="join__fnsPartner _row">
                    <div className="join__fnsPartnerLogo">
                        <img
                            src={require('../../../../img/crm/joins/app-logo.svg').default}
                            alt=""
                            className="join__fnsPartnerLogoIcon"
                        />
                    </div>
                    <div className="join__fnsPartnerContent">
                        <b>LIVECARGO CRM —</b>
                        <br />
                        официальный партнёр ФНС
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatusSmzAccess;
