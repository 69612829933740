import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';

class TaxAbout extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appTaxAboutPopup', isShow: false });
    }

    render() {
        return (
            <div ref={this.parent} className="appPopup _col _taxAbout">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <img
                                src={require('../../../img/app/tax-about-icon.svg').default}
                                alt=""
                                className="appPopup__icon"
                            />
                            <div className="appPopup__title">
                                Как работает
                                <br />
                                налоговая копилка?
                            </div>
                            <p className="appPopup__description">
                                Автоматически отложим налог с вашего дохода. До 28-го числа текущего
                                месяца оплатим налог за предыдущий месяц.
                            </p>
                            <p className="appPopup__description">
                                <b>
                                    Вы сможете отслеживать все операции по&nbsp;налогам на странице
                                    налоговой копилки.
                                </b>
                            </p>
                            <div className="appPopup__button">
                                <Button onClick={this.hide}>Понятно</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(TaxAbout);

TaxAbout.propTypes = {
    windowHeight: PropTypes.number,
    hideCallback: PropTypes.func,
};
