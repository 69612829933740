import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerPopup from '../../../functions/app/handlerPopup';
import getFormatPrice from '../../../functions/getFormatPrice';
import getFormatedDate from '../../../functions/getFormatedDate';
import setSpacesInText from '../../../functions/setSpacesInText';

import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Button from '../../Button.jsx';
import Icon from '../../Icon.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';

class PayPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderButton = this.renderButton.bind(this);
    }

    successSign() {
        document.dispatchEvent(new CustomEvent('changePays'));
    }

    getButtons() {
        const { status, fnsReceiptLink, isExecutorSign } = this.props;

        if (status === 'process' && !isExecutorSign) {
            return [{ key: 'process' }];
        }

        if (fnsReceiptLink) {
            return [{ key: 'link' }];
        }

        return [];
    }

    renderButton({ prop: key }) {
        const { _id, number, fnsReceiptLink, fnsReceiptStatus, createdDate } = this.props;

        if (key === 'link') {
            return (
                <div
                    className="payPreview__button"
                    onClick={() => {
                        handlerPopup({
                            name: 'appPayInfoPopup',
                            isShow: true,
                            payId: _id,
                            payNumber: number,
                            payDate: createdDate,
                            status: fnsReceiptStatus,
                            link: fnsReceiptLink,
                            withCancel: fnsReceiptStatus === 'completed',
                        });
                    }}
                >
                    <Button className="_blue">Посмотреть чек</Button>
                </div>
            );
        }

        if (key === 'process') {
            return (
                <div className="payPreview__button">
                    <Button
                        className="_blue"
                        onClick={() => {
                            handlerPopup({
                                name: 'appSignPayPopup',
                                isShow: true,
                                payId: _id,
                                callback: this.successSign,
                            });
                        }}
                    >
                        Подписать акт
                    </Button>
                </div>
            );
        }

        return null;
    }

    render() {
        const {
            user,
            number,
            status,
            name,
            startPeriod,
            endPeriod,
            amount,
            transactionErrors,
            isExecutorSign,
            setUpdate,
            fnsReceiptStatus,
            transactionErrorText,
            corporationName,
        } = this.props;
        const iconName =
            status === 'completed'
                ? 'app-order-complete'
                : status === 'failed'
                  ? 'app-order-cancel'
                  : 'app-order-wait';
        const statusText =
            status === 'completed'
                ? 'Оплачен'
                : status === 'failed'
                  ? 'Ошибка'
                  : !isExecutorSign
                    ? 'На подписи'
                    : 'В процессе';
        let fnsStatusText = 'Не сформирован';
        let fnsStatus = 'wait';

        if (fnsReceiptStatus === 'cancel') {
            fnsStatusText = 'Аннулирован';
            fnsStatus = 'error';
        }

        if (fnsReceiptStatus === 'completed' || fnsReceiptStatus === 'offline') {
            fnsStatusText = 'Сформирован';
            fnsStatus = 'completed';
        }

        return (
            <div className="payPreview">
                <div className="payPreview__inner">
                    <div className="payPreview__head _row">
                        <div className="payPreview__headTitle">
                            <AnimateChangeUp className="payPreview__headIcon" renderKey={iconName}>
                                <Icon name={iconName} />
                            </AnimateChangeUp>
                            № {number}
                        </div>
                        <div className="payPreview__headPeriods">
                            {getFormatedDate({ date: new Date(startPeriod), isShortYear: true })} –{' '}
                            {getFormatedDate({ date: new Date(endPeriod), isShortYear: true })}
                        </div>
                    </div>
                    <div className="payPreview__content">
                        <div className="payPreview__name">{name}</div>
                        <div className="payPreview__details">
                            <div className="payPreview__detailsItem">
                                <div className="payPreview__detailsItemInner _row">
                                    <div className="payPreview__detailsItemValue _support">
                                        Заказчик:
                                    </div>
                                    <div className="payPreview__detailsItemValue">
                                        {corporationName}
                                    </div>
                                </div>
                            </div>
                            <div className="payPreview__detailsItem">
                                <div className="payPreview__detailsItemInner _row">
                                    <div className="payPreview__detailsItemValue _support">
                                        Сумма:
                                    </div>
                                    <div className="payPreview__detailsItemValue">
                                        {getFormatPrice(amount)}₽
                                    </div>
                                </div>
                            </div>
                            <div className="payPreview__detailsItem">
                                <div className="payPreview__detailsItemInner _row">
                                    <div className="payPreview__detailsItemValue _support">
                                        Акт:
                                    </div>
                                    <div className="payPreview__detailsItemValue _status _completed">
                                        Подписан
                                    </div>
                                </div>
                            </div>
                            <div className="payPreview__detailsItem">
                                <div className="payPreview__detailsItemInner _row">
                                    <div className="payPreview__detailsItemValue _support">
                                        Статус:
                                    </div>
                                    <AnimateChangeUp
                                        renderKey={statusText}
                                        className={`payPreview__detailsItemValue _status ${
                                            status === 'completed'
                                                ? '_completed'
                                                : status === 'failed'
                                                  ? '_error'
                                                  : '_process'
                                        }`}
                                    >
                                        <>{statusText}</>
                                    </AnimateChangeUp>
                                </div>
                            </div>
                            {user.organization === 'SMZ' && (
                                <div className="payPreview__detailsItem">
                                    <div className="payPreview__detailsItemInner _row">
                                        <div className="payPreview__detailsItemValue _support">
                                            Чек:
                                        </div>
                                        <AnimateChangeUp
                                            renderKey={fnsStatusText}
                                            className={`payPreview__detailsItemValue _status _${fnsStatus}`}
                                        >
                                            <>{fnsStatusText}</>
                                        </AnimateChangeUp>
                                    </div>
                                </div>
                            )}
                            <AnimateChangeUp
                                className="payPreview__detailsItem _dynamic"
                                renderKey={
                                    status === 'failed' && transactionErrors.length > 0
                                        ? true
                                        : undefined
                                }
                                paramsParent={{ width: true }}
                                parentStyles={['height']}
                                callback={({ isChangeLen }) => {
                                    if (isChangeLen) {
                                        setUpdate();
                                    }
                                }}
                            >
                                <div className="payPreview__detailsItemInner _row">
                                    {status === 'failed' && transactionErrors.length > 0 ? (
                                        <>
                                            <div className="payPreview__detailsItemValue _support">
                                                Детали:
                                            </div>
                                            <div
                                                className="payPreview__detailsItemValue"
                                                dangerouslySetInnerHTML={{
                                                    __html: setSpacesInText(transactionErrorText),
                                                }}
                                            ></div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </AnimateChangeUp>
                        </div>

                        <ListAbsoluteMain
                            className="payPreview__buttons"
                            items={this.getButtons()}
                            renderItem={this.renderButton}
                            classNameItem="payPreview__button"
                            prop="key"
                            paramsParent={{ width: true }}
                            styles={[]}
                            isSmoothShow={true}
                            isNotParams={true}
                            isNotParamsItem={true}
                            callback={({ isChangeLen }) => {
                                if (isChangeLen) {
                                    setUpdate();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(PayPreview);

PayPreview.propTypes = {
    _id: PropTypes.string,
    number: PropTypes.number,
    status: PropTypes.string,
    name: PropTypes.string,
    startPeriod: PropTypes.string,
    endPeriod: PropTypes.string,
    createdDate: PropTypes.string,
    amount: PropTypes.number,
    user: PropTypes.object,
    fnsReceiptLink: PropTypes.string,
    transactionErrors: PropTypes.array,
    isExecutorSign: PropTypes.bool,
    setUpdate: PropTypes.func,
    fnsReceiptStatus: PropTypes.string,
    transactionErrorText: PropTypes.string,
};
