import React from 'react';

import I from '../types.ts';

const renderHead: I['renderHead'] = function () {
    const { joinScript } = this.state;

    return (
        <>
            <div className={`join__head`}>
                <div className="join__headInner _ROW">
                    <div className="join__logo">
                        <p className="join__logoName">Livecargo</p>
                        <img
                            src={require('../../../../img/crm/icon-logo.svg').default}
                            alt=""
                            className="join__logoIcon"
                        />
                    </div>
                    <div className="join__headTitle">
                        Подключение
                        <br />
                        <b>к&nbsp;«{joinScript?.corporationName || ''}»</b>
                    </div>
                </div>
                {this.renderTabs()}
            </div>
        </>
    );
};

export default renderHead;
