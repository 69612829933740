import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import setTheme from '@functions/app/setTheme.ts';

import Animate from '../../components/Animate.jsx';
import Loader from '../../components/Loader.jsx';
import Pages from '../../components/Pages.jsx';
import Back from '../../components/app/Back.jsx';
import HeadPage from '../../components/app/HeadPage.jsx';

import changePage from '../../functions/changePage';
import getCurrentPage from '../../functions/getCurrentPage';
import getPageLink from '../../functions/getPageLink';
import removeTransition from '../../functions/removeTransition.ts';

import Fns from './settings/Fns.jsx';
import Index from './settings/Index.jsx';
import Legal from './settings/Legal.jsx';
import Profile from './settings/Profile.jsx';
import Tax from './settings/Tax.jsx';
import Wallet from './settings/Wallet.jsx';

class Settings extends HeadPage {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerBack = this.handlerBack.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
    }

    tabsOrder = ['light', 'dark'];

    tabs = {
        light: {
            content: 'Светлый режим',
        },
        dark: {
            content: 'Тёмный режим',
        },
    };

    checkCurrentTab(name) {
        const { theme } = this.props;

        return name === theme;
    }

    changeTab(name) {
        setTheme(name);
    }

    titles = {
        'settings-index': {
            render() {
                return <>Настройки</>;
            },
        },
        'settings-profile': {
            render() {
                return <>Данные аккаунта</>;
            },
        },
        'settings-legal': {
            render() {
                return <>О приложении</>;
            },
        },
        'settings-wallet': {
            render() {
                return <>Реквизиты</>;
            },
        },
        'settings-tax': {
            render() {
                return <>Налоговая копилка</>;
            },
        },
        'settings-fns': {
            render() {
                return <>Мой налог</>;
            },
        },
    };

    handlerBack() {
        const { pagesStore } = this.props;
        const currentPage = getCurrentPage({
            pages: pagesStore,
            filter: (page) => page.parentName === 'settings',
        });
        const currentPageDeep = getCurrentPage({
            pages: pagesStore,
            filter: (page) => page.parentName === 'settings-legal',
        });

        let href = 'index';

        if (currentPageDeep === 'settings-legal-inner') {
            href = 'settings-legal';
        } else if (currentPage !== 'settings-index') {
            href = 'settings-index';
        }

        changePage({ href: getPageLink({ name: href }) });
    }

    pages = {
        'settings-index': {
            render() {
                return (
                    <div className="headPage__innerBoxContent">
                        <Index />
                    </div>
                );
            },
        },
        'settings-profile': {
            render() {
                return (
                    <div className="headPage__innerBoxContent">
                        <Profile />
                    </div>
                );
            },
        },
        'settings-legal': {
            render() {
                return (
                    <div className="headPage__innerBoxContent">
                        <Legal />
                    </div>
                );
            },
        },
        'settings-wallet': {
            render() {
                return (
                    <div className="headPage__innerBoxContent">
                        <Wallet />
                    </div>
                );
            },
        },
        'settings-tax': {
            render() {
                const { isShowLoaderList } = this.state;

                return (
                    <>
                        <div className="headPage__innerBoxContent">
                            <Tax handlerLoaderList={this.handlerLoaderList} />
                        </div>
                        <Animate
                            className="settingsTax__historyContentLoader _loaderScroll"
                            isShow={isShowLoaderList}
                        >
                            <div className="settingsTax__historyContentLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
        'settings-fns': {
            render() {
                return (
                    <div className="headPage__innerBoxContent">
                        <Fns />
                    </div>
                );
            },
        },
    };

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    componentDidMount() {
        super.componentDidMount();

        removeTransition({ item: '.pageHead__tabsBack' });
    }

    render() {
        return (
            <div ref={this.parent} className="headPage">
                <div className="headPage__head">
                    <div className="pageHead _col">
                        <div className="pageHead__top _row">
                            <div className="pageHead__back">
                                <Back handler={this.handlerBack} />
                            </div>
                            <div className="pageHead__titles">
                                <Pages
                                    classNamePage="pageHead__title"
                                    filter={(page) => page.parentName === 'settings'}
                                    pages={this.titles}
                                    context={this}
                                    fullHeight={false}
                                />
                            </div>
                        </div>
                        {this.renderTabs()}
                    </div>
                </div>
                <div className="headPage__inner _pages">
                    <Pages
                        classNamePage="headPage__innerBox _noScroll"
                        filter={(page) => page.parentName === 'settings'}
                        pages={this.pages}
                        context={this}
                        fullHeight={false}
                        getClassName={(name) =>
                            ['settings-legal', 'settings-tax'].includes(name) ? '_notPadding' : ''
                        }
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        pagesStore: state.pages,
        theme: state.theme,
    };
}

export default connect(mapStateToProps)(Settings);

Settings.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    pagesStore: PropTypes.object,
    theme: PropTypes.string,
};
