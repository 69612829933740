import React from 'react';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import I from '../types.ts';

const renderBlocks: I['renderBlocks'] = function () {
    const { joinScript, updatedKey, currentStatusStep } = this.state;
    let currentStep: string = this.state.currentStep;

    if (currentStep === 'status') {
        currentStep = currentStatusStep;
    }

    const allSteps = Object.keys(this.tabs).reduce(
        (prev: string[], cur) =>
            prev.concat(
                ...(cur === 'status' ? (Object.keys(this.statusBlocks) as string[]) : [cur]),
            ),
        [],
    );

    return (
        <div className="join__blocks">
            {joinScript && (
                <ListAbsoluteMain
                    className="join__blocksInner"
                    items={[{ key: currentStep }]}
                    renderItem={this.renderBlock.bind(this)}
                    classNameItem="join__block"
                    prop="key"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    parentStyle={
                        ['start', 'check', 'final'].includes(this.state.currentStep)
                            ? { height: '100%' }
                            : {}
                    }
                    allItems={allSteps}
                    isNotParamsItem={true}
                    currentItemKey={currentStep}
                    keyRender={updatedKey}
                />
            )}
        </div>
    );
};

export default renderBlocks;
