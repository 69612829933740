import changePage from '../functions/changePage';

export default function init() {
    if (process.env.REACT_APP_SYSTEM === 'app' && process.env.REACT_APP_ENV !== 'local') {
        const registerWorker = () => {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then(
                    () => {
                        const swListener = new BroadcastChannel('swListener');

                        swListener.onmessage = (e) => {
                            const data = JSON.parse(e.data);
                            const { id, type } = data;

                            let pageName;

                            if (type === 'payWaiting') {
                                pageName = 'pays-process';
                            } else if (type === 'payCompleted') {
                                pageName = 'pays-completed';
                            } else if (type === 'newMessage') {
                                pageName = 'chat-inner';
                            }

                            if (pageName) {
                                changePage({ href: pageName, id });
                            }
                        };
                    },
                    (err) => {
                        console.log(err);
                    },
                )
                .catch((err) => {
                    console.log(err);
                });
        };

        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                registerWorker();
            });
        } else {
            console.log('service worker is not supported');
        }
    }
}
