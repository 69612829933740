import React from 'react';

import { BlockT } from '../types.ts';

const StatusSmzCheck: BlockT = function () {
    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__fnsLogo _col">
                    <img
                        src={require('../../../../img/fns-logo.svg').default}
                        alt=""
                        className="join__fnsLogoIcon"
                    />
                </div>
                <div className="join__blockTitle">Подтвердите подключение</div>
                <div className="join__blockText _marginBottom">
                    Перейдите в «Мой налог» —<br />
                    Раздел «Прочее» — «Партнеры» —<br />
                    «Доступные» и подтвердите
                    <br />
                    подключение
                </div>
                {this.renderError()}
                {this.renderButton({
                    name: 'status-smz-check',
                    text: 'Проверить подключение',
                    // onClick: this.getHandlerButton(),
                    // withArrow: false,
                    className: '_notTop',
                })}
            </div>
        </>
    );
};

export default StatusSmzCheck;
