export default class MobSlider {
    constructor(settings) {
        this.id = settings.id;
        this.slider = settings.slider;
        this.lay = settings.lay;
        this.itemsStart = Object.assign([], settings.items);
        this.items = Object.assign([], settings.items);
        this.buttons = settings.buttons;
        this.length = this.items.length;
        this.type = settings.type;
        this.current = settings.current;
        this.pagination = settings.pagination;
        this.infinity = settings.infinity;
        this.showEachSlide = settings.showEachSlide;
        this.calcHeight = settings.calcHeight;
        this.calcBox = settings.calcBox;
        this.loop = settings.loop;
        this.includeData = settings.includeData;
        this.different = settings.different;
        this.itemsWidth = [];
        this.withResize = settings.withResize;
        this.transition = '.3s ease-in-out';
        this.time = {
            value: 0,
            intervalId: null,
        };
        this.data = settings.data;
        this.windowWidth = window.widthValue;

        this.moveAction = this.moveAction.bind(this);
        this.sliderInit = this.sliderInit.bind(this);
        this.addItems = this.addItems.bind(this);
        this.resetSlider = this.resetSlider.bind(this);
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.calcTimeStep = this.calcTimeStep.bind(this);
        this.setCurrentSlid = this.setCurrentSlid.bind(this);
        this.getHeightCurrentStep = this.getHeightCurrentStep.bind(this);
        this.setLoop = this.setLoop.bind(this);
        this.getWidthStep = this.getWidthStep.bind(this);
        this.setInclude = this.setInclude.bind(this);
        this.setNeedItem = this.setNeedItem.bind(this);

        if (
            (this.type === 'notDesktop' && this.windowWidth <= 960) ||
            (this.type === 'mobile' && this.windowWidth <= 450) ||
            !this.type
        ) {
            this.sliderInit();
        }

        document.dispatchEvent(
            new CustomEvent('change-slider', {
                detail: {
                    id: this.id,
                    type: 'change',
                    current: this.current,
                    data: this.data,
                },
            }),
        );

        if (this.withResize) {
            document.addEventListener('changeWidthWindow', () => {
                this.resetSlider({});

                setTimeout(() => {
                    this.resetSlider({});
                }, 10);
            });
        }
    }

    calcTimeStep() {
        this.time.value = 0;
        this.time.intervalId = setInterval(() => {
            this.time.value += 10;
        }, 10);
    }

    touchStart(event) {
        if (this.slider != null) {
            this.flag = true;
            this.flagEnd = false;
            this.containFlag = false;
            this.lay.style.transition = `0s`;
            this.startX = event.changedTouches[0].pageX;
            this.startY = event.changedTouches[0].pageY;
            this.flagX = false;
            this.flagY = false;
            this.calcTimeStep();
            if (this.loopId) {
                clearInterval(this.loopId);
            }
        }
    }

    touchMove(event) {
        if (this.slider != null) {
            if (this.flag && !this.containFlag) {
                this.moveStep = event.changedTouches[0].pageX - this.startX;
                this.moveX = event.changedTouches[0].pageX - this.startX + this.move;
                this.moveY = event.changedTouches[0].pageY - this.startY;
                if (event.changedTouches[0].pageX - this.startX < 0) {
                    this.dir = 'prev';
                } else {
                    this.dir = 'next';
                }
                if (Math.abs(this.moveY) > 25 && !this.flagX) {
                    this.flagY = true;
                    this.flagX = false;
                }
                if (Math.abs(event.changedTouches[0].pageX - this.startX) > 3 && !this.flagY) {
                    this.flagX = true;
                    this.flagY = false;
                    event.preventDefault();
                    event.stopPropagation();
                }
                if (this.flagX) {
                    let move = this.moveX + (this.dir === 'prev' ? 6 : -6);
                    if (this.infinity === false && move > 0) {
                        move /= 4;
                    }
                    if (
                        this.infinity === false &&
                        move < -(this.widthItem * (this.length - this.includeWindow))
                    ) {
                        move =
                            -(this.widthItem * (this.length - this.includeWindow)) +
                            (move + this.widthItem * (this.length - this.includeWindow)) / 4;
                    }
                    this.moveLay(move);
                }
            }
        }
    }

    touchEnd() {
        let cur;
        this.items.forEach((item) => {
            if (item.getAttribute('data-current') === 'true') {
                cur = +item.getAttribute('data-id');
            }
        });

        if (this.slider != null && this.flag) {
            clearInterval(this.time.intervalId);
            if (this.flagX) {
                const delta = Math.abs(this.moveStep);
                const speed = this.time.value / delta;

                if (this.dir === 'prev') {
                    if (
                        this.moveX + 35 < this.move &&
                        this.flag &&
                        ((this.infinity === false &&
                            cur + this.includeWindow !== this.items.length) ||
                            this.infinity === true)
                    ) {
                        this.moveAction('next', speed);
                    } else {
                        this.lay.style.transition = this.transition;

                        this.moveLay(this.move);
                        setTimeout(() => {
                            this.lay.style.transition = `0s`;
                            this.flagEnd = true;
                        }, 510);
                    }
                } else if (
                    this.moveX - 35 > this.move &&
                    this.flag &&
                    ((this.infinity === false && cur !== 0) || this.infinity === true)
                ) {
                    this.moveAction('prev', speed);
                } else {
                    this.lay.style.transition = this.transition;
                    this.moveLay(this.move);
                    setTimeout(() => {
                        this.lay.style.transition = `0s`;
                        this.flagEnd = true;
                    }, 510);
                }
            } else {
                this.flagEnd = true;
            }
            this.flag = false;
            this.startX = 0;
            this.moveX = 0;
            this.setLoop();
        }
    }

    getHeightCurrentStep(current) {
        let heightStep;
        this.itemsStart.forEach((item, key) => {
            if (current === key) {
                heightStep = item.clientHeight;
            }
        });
        return heightStep;
    }

    setLoop() {
        if (this.loop) {
            if (this.loopId) {
                clearInterval(this.loopId);
            }
            this.loopId = setInterval(() => {
                this.moveAction('next');
            }, this.loop);
        }
    }

    setNeedItem(currentKey, isHard = false) {
        let current;
        let key = currentKey;

        if (key > this.length - this.includeWindow && this.showEachSlide !== true) {
            key = this.length - this.includeWindow;
        }

        if (this.showEachSlide === true && key > this.length) {
            key = this.length;
        }

        if (key < 0) {
            key = 0;
        }

        this.items.forEach((item, keyItem) => {
            if (item.getAttribute('data-current')) {
                current = +item.getAttribute('data-id');
            }

            if (this.pagination) {
                const dot = this.slider.querySelector(
                    `.${this.pagination.dot}[data-id="${keyItem}"]`,
                );

                if (dot) {
                    dot.classList.remove('_current');
                }
            }
        });

        this.move = -this.getWidthStep() * key;
        if (!isHard) {
            this.lay.style.transition = this.transition;
        }

        setTimeout(() => {
            this.moveLay(this.move);
        }, 10);

        setTimeout(() => {
            this.lay.style.transition = `0s`;
        }, 500);

        this.items.forEach((item) => {
            if (+item.getAttribute('data-id') === key) {
                item.setAttribute('data-current', 'true');
                if (this.pagination) {
                    const dot = this.slider.querySelector(
                        `.${this.pagination.dot}[data-id="${key}"]`,
                    );

                    if (dot) {
                        dot.classList.add('_current');
                    }
                }
            } else {
                item.removeAttribute('data-current');
            }
        });

        if (this.buttons) {
            if (key > current) {
                if (this.buttons.prev) {
                    this.buttons.prev.removeAttribute('data-disable');
                }
                if (key + this.includeWindow === this.length) {
                    this.buttons.next.setAttribute('data-disable', true);
                }
            }
            if (key < current) {
                if (this.buttons.next) {
                    this.buttons.next.removeAttribute('data-disable');
                }
                if (key === 0) {
                    this.buttons.prev.setAttribute('data-disable', true);
                }
            }
        }

        document.dispatchEvent(
            new CustomEvent('change-slider', {
                detail: {
                    id: this.id,
                    type: 'change',
                    current: key,
                    data: this.data,
                },
            }),
        );
    }

    sliderInit() {
        this.lay.style.position = 'relative';
        this.lay.style.userSelect = 'none';
        this.widthLay = this.lay.offsetWidth;
        this.widthItem = this.widthLay / this.length;
        let realyWidthItem;
        let widthItemsVal = 0;
        const widthItems = [];

        this.itemsStart.forEach((item) => {
            if (!realyWidthItem) {
                realyWidthItem = item.clientWidth;
            }

            widthItemsVal += item.offsetWidth;

            widthItems.push(item.offsetWidth);
        });

        this.includeWindow = Math.floor(this.slider.offsetWidth / realyWidthItem);

        this.margin = (this.widthLay - widthItemsVal) / this.itemsStart.length;
        this.widthItems = widthItems;

        if (this.buttons) {
            ['prev', 'next'].forEach((key) => {
                const button = this.buttons[key];

                if (button) {
                    button.removeAttribute('data-disable');
                }
            });
        }

        if (
            (this.includeWindow >= this.length && this.showEachSlide !== true) ||
            this.itemsStart.length <= 1
        ) {
            this.itemsStart[0].setAttribute('data-current', 'true');

            this.itemsStart.forEach((item) => {
                item.setAttribute('data-include', 'true');
            });

            if (this.buttons && this.buttons.parent) {
                this.buttons.parent.style.display = 'none';
            }

            if (this.buttons) {
                ['prev', 'next'].forEach((key) => {
                    const button = this.buttons[key];

                    if (button) {
                        button.setAttribute('data-disable', true);
                    }
                });
            }

            return false;
        }

        if (this.showEachSlide === true) {
            this.includeWindow = 1;
        }

        if (this.includeData) {
            this.includeWindow = this.includeData;
        }

        this.move = 0;
        this.flag = false;
        this.flagEnd = true;
        this.containFlag = false;
        if (this.loop) {
            this.setLoop();
        }
        if (this.pagination) {
            if (this.pagination.current) {
                this.pagination.current.innerHTML = this.current + 1;
            }
            if (this.pagination.all) {
                this.pagination.all.innerHTML = this.length - this.includeWindow + 1;
            }
            if (this.pagination.parent) {
                this.pagination.parent.innerHTML = '';
            }
        }

        if (this.includeWindow === 1) {
            if (this.calcHeight === true) {
                // this.calcBox.style.overflow = 'hidden';
                this.calcBox.style.height = `${this.getHeightCurrentStep(this.current)}px`;
            } else if (this.calcBox) {
                // this.calcBox.style.overflow = null;
                this.calcBox.style.height = null;
            }
        } else if (this.calcBox) {
            this.calcBox.style.height = null;
        }

        this.itemsStart.forEach((item, key) => {
            item.setAttribute('data-id', key);
            item.removeAttribute('data-current');

            if (this.current === key) {
                item.setAttribute('data-current', true);
            }

            if (this.showEachSlide || key < this.itemsStart.length - this.includeWindow + 1) {
                if (this.pagination && this.pagination.dot) {
                    const dot = document.createElement('div');

                    dot.classList.add(this.pagination.dot);
                    dot.setAttribute('data-id', key);

                    if (this.current === key) {
                        dot.classList.add('_current');
                    }

                    this.pagination.parent.appendChild(dot);

                    if (this.infinity !== true) {
                        dot.onclick = () => {
                            this.setNeedItem(key);
                        };
                    }
                }
                if (this.different === true) {
                    this.itemsWidth.push(item.offsetWidth);
                }
            }
        });

        this.setInclude(this.current);
        if (this.different === true) {
            const itemsWidthSum = this.itemsWidth.reduce((a, b) => a + b);
            this.offset = (this.lay.offsetWidth - itemsWidthSum) / this.length;
        }

        this.left = this.lay.getBoundingClientRect().x;
        this.right = this.windowWidth - (this.lay.getBoundingClientRect().x + this.lay.offsetWidth);
        if (this.right < 0) {
            this.right = 0;
        }
        if (this.left < 0) {
            this.left = 0;
        }
        if (this.infinity !== false) {
            this.addItems(
                'next',
                Math.ceil(this.right / this.widthItem) +
                    Math.ceil(this.windowWidth / this.widthItem),
            );
            this.addItems(
                'prev',
                Math.ceil(this.left / this.widthItem) +
                    Math.ceil(this.windowWidth / this.widthItem),
            );
        }

        this.startX = 0;
        this.moveX = 0;
        this.startY = 0;
        this.moveY = 0;

        if (this.buttons) {
            ['prev', 'next'].forEach((key) => {
                const button = this.buttons[key];

                if (button) {
                    button.onclick = () => {
                        const disable = button.getAttribute('data-disable');

                        if (this.flagEnd && !disable) {
                            if (this.loop) {
                                this.setLoop();
                            }
                            this.moveAction(key);
                        }
                    };
                }
            });

            if (this.infinity === false && this.current === 0 && this.buttons.prev) {
                this.buttons.prev.setAttribute('data-disable', true);
            }
        }

        this.setNeedItem(this.current, true);

        this.lay.addEventListener('touchstart', this.touchStart);
        this.slider.addEventListener('touchmove', this.touchMove, {
            passive: false,
        });
        this.slider.addEventListener('touchend', this.touchEnd);

        return true;
    }

    addItems(direction, count) {
        let iteration;
        let newItem;

        switch (direction) {
            case 'next':
                iteration = 0;
                for (let i = 0; i < count; i++) {
                    if (iteration === this.length) {
                        iteration = 0;
                    }
                    this.items.forEach((item) => {
                        if (+item.getAttribute('data-id') === iteration) {
                            newItem = item.cloneNode(true);
                        }
                    });
                    newItem.removeAttribute('data-current');
                    iteration++;
                    this.lay.append(newItem);
                    this.items.push(newItem);
                }
                break;
            case 'prev':
                {
                    let left = 0;

                    iteration = this.length - 1;

                    for (let i = count; i > 0; i--) {
                        if (iteration === -1) {
                            iteration = this.length - 1;
                        }
                        left -= this.widthItem;
                        this.items.forEach((item) => {
                            if (+item.getAttribute('data-id') === iteration) {
                                newItem = item.cloneNode(true);
                            }
                        });
                        newItem.removeAttribute('data-current');
                        iteration--;
                        this.lay.prepend(newItem);
                        this.items.unshift(newItem);
                        this.lay.style.left = `${left}px`;
                        this.left = left;
                    }
                }
                break;
            default:
                break;
        }
    }

    set(key, value) {
        this[key] = value;
    }

    resetSlider() {
        this.lay.innerHTML = '';
        this.slider.removeAttribute('style');
        this.lay.removeAttribute('style');
        this.itemsStart.forEach((item) => {
            item.removeAttribute('data-id');
            item.removeAttribute('data-current');
            item.removeAttribute('data-include');
            this.lay.append(item);
        });
        this.items = Object.assign([], this.itemsStart);
        this.windowWidth = window.widthValue;

        if (
            (this.type === 'notDesktop' && this.windowWidth <= 960) ||
            (this.type === 'mobile' && this.windowWidth <= 450) ||
            !this.type
        ) {
            this.sliderInit();
        } else {
            this.destroy();
        }
    }

    moveLay(value, current, direction) {
        this.lay.style.transform = `translate3d(${value}px,0,0)`;

        if (this.calcHeight === true && this.includeWindow === 1) {
            this.calcBox.style.height = `${this.getHeightCurrentStep(
                direction === 'next' ? current + 1 : current - 1,
            )}px`;
        }
    }

    getWidthStep(current = 0) {
        if (this.showEachSlide) {
            return this.widthItems[current] + this.margin;
        }

        if (this.different === true) {
            return current !== null ? this.itemsWidth[current] + this.offset : this.widthItem;
        }

        return this.widthItem;
    }

    moveAction(direction) {
        let cloneItem;
        let idCloneItem;
        let currentItem;
        let clone;
        let removeChild;
        let cur;
        this.flagEnd = false;
        this.items.forEach((item) => {
            if (item.getAttribute('data-current') === 'true') {
                currentItem = item;
                cur = +item.getAttribute('data-id');
            }
        });

        if (
            this.infinity === false &&
            ((cur === 0 && direction === 'prev') ||
                (cur + this.includeWindow === this.length && direction === 'next'))
        ) {
            this.flagEnd = true;
            if (this.windowWidth < 760) {
                this.lay.style.transition = this.transition;
                this.moveLay(this.move, cur, direction);
            }
            return false;
        }

        const setPag = () => {
            let current;
            this.items.forEach((item) => {
                if (item.getAttribute('data-current') === 'true') {
                    current = +item.getAttribute('data-id') + 1;
                }
                if (this.pagination && this.pagination.dot) {
                    const dot = this.slider.querySelector(
                        `.${this.pagination.dot}[data-id="${item.getAttribute('data-id')}"]`,
                    );

                    if (dot) {
                        dot.classList.remove('_current');
                    }
                }
            });

            const currentForInclude = current - 1;

            this.setInclude(currentForInclude);
            document.dispatchEvent(
                new CustomEvent('change-slider', {
                    detail: {
                        id: this.id,
                        type: 'change',
                        current: current - 1,
                        data: this.data,
                    },
                }),
            );
            if (this.pagination) {
                if (this.pagination.current) {
                    this.pagination.current.innerHTML = current;
                }
                if (
                    this.pagination.dot &&
                    this.slider.querySelector(`.${this.pagination.dot}[data-id="${current - 1}"]`)
                ) {
                    this.slider
                        .querySelector(`.${this.pagination.dot}[data-id="${current - 1}"]`)
                        .classList.add('_current');
                }
            }
        };

        const getEl = (el) => {
            switch (direction) {
                case 'next':
                    return el.nextElementSibling;
                case 'prev':
                    return el.previousElementSibling;
                default:
                    return null;
            }
        };

        const moveWithSpeed = (k) => {
            this.move += k * this.getWidthStep(direction === 'next' ? cur : cur - 1);
        };

        const setCurrent = () => {
            if (currentItem) {
                getEl(currentItem).setAttribute('data-current', true);
            }
        };

        switch (direction) {
            case 'next':
                this.items.forEach((item) => {
                    if (item === this.lay.lastElementChild) {
                        cloneItem = item;
                    }
                    if (item === this.lay.firstElementChild) {
                        removeChild = item;
                    }
                });
                if (cloneItem) {
                    idCloneItem = +cloneItem.getAttribute('data-id') + 1;
                    if (idCloneItem === this.length) {
                        idCloneItem = 0;
                    }
                }
                if (currentItem) {
                    currentItem.removeAttribute('data-current');
                }

                if (currentItem && !currentItem.nextElementSibling) {
                    if (this.infinity === false) {
                        currentItem.setAttribute('data-current', true);
                        this.move += this.getWidthStep(cur);
                    }
                }

                moveWithSpeed(-1);
                if (
                    (currentItem &&
                        currentItem.nextElementSibling &&
                        +currentItem.nextElementSibling.getAttribute('data-id') +
                            this.includeWindow >=
                            this.length) ||
                    (currentItem &&
                        +currentItem.getAttribute('data-id') + this.includeWindow >= this.length)
                ) {
                    if (this.infinity === false && this.windowWidth < 760) {
                        // this.move = -this.getWidthStep(cur) * (this.length - this.includeWindow);
                    }
                }

                setCurrent();
                setPag();

                if (this.infinity === false) {
                    if (this.buttons) {
                        if (this.buttons.prev) {
                            this.buttons.prev.removeAttribute('data-disable');
                        }
                        if (
                            +currentItem.getAttribute('data-id') + this.includeWindow ===
                            this.length - 1
                        ) {
                            this.buttons.next.setAttribute('data-disable', true);
                        }
                    }
                }
                if (this.infinity !== false) {
                    setTimeout(() => {
                        this.lay.style.transition = this.transition;
                        this.moveLay(this.move, cur, direction);
                    }, 10);
                }
                if (this.infinity === false) {
                    if (+currentItem.getAttribute('data-id') + this.includeWindow >= this.length) {
                        this.flagEnd = true;

                        return false;
                    }

                    setTimeout(() => {
                        this.lay.style.transition = this.transition;
                        this.moveLay(this.move, cur, direction);
                    }, 10);
                }
                setTimeout(() => {
                    this.items.forEach((item) => {
                        if (+item.getAttribute('data-id') === idCloneItem) {
                            clone = item.cloneNode(true);
                            clone.removeAttribute('data-current');
                        }
                    });

                    if (this.infinity !== false) {
                        this.lay.append(clone);
                        this.items.push(clone);
                        this.left += this.getWidthStep(cur);
                        this.lay.style.left = `${this.left}px`;
                    }

                    if (removeChild && this.infinity !== false && removeChild.parentNode) {
                        removeChild.parentNode.removeChild(removeChild);
                    }
                    this.lay.style.transition = `0s`;
                    this.flagEnd = true;
                    document.dispatchEvent(
                        new CustomEvent('create-slider-item', {
                            detail: { id: this.id, type: 'clone' },
                        }),
                    );
                }, 510);
                break;
            case 'prev':
                this.items.forEach((item) => {
                    if (item === this.lay.firstElementChild) {
                        cloneItem = item;
                    }
                    if (item === this.lay.lastElementChild) {
                        removeChild = item;
                    }
                });
                if (cloneItem) {
                    idCloneItem = +cloneItem.getAttribute('data-id') - 1;
                    if (idCloneItem === -1) {
                        idCloneItem = this.length - 1;
                    }
                }
                if (currentItem) {
                    currentItem.removeAttribute('data-current');
                }

                setCurrent();
                if (this.infinity === false) {
                    if (this.buttons) {
                        if (this.buttons.next) {
                            this.buttons.next.removeAttribute('data-disable');
                        }
                        if (+currentItem.getAttribute('data-id') === 1) {
                            this.buttons.prev.setAttribute('data-disable', true);
                        }
                    }
                }
                if (currentItem.previousElementSibling) {
                    // currentItem.previousElementSibling.setAttribute('data-current', true)
                } else if (this.infinity === false) {
                    currentItem.setAttribute('data-current', true);
                    this.move -= this.getWidthStep(cur);
                }

                setPag();
                moveWithSpeed(1);

                if (
                    +currentItem.getAttribute('data-id') === 0 ||
                    (currentItem.previousElementSibling &&
                        +currentItem.previousElementSibling.getAttribute('data-id') === 0)
                ) {
                    if (this.infinity === false) {
                        this.move = 0;
                    }
                }

                setTimeout(() => {
                    this.lay.style.transition = this.transition;
                    this.moveLay(this.move, cur, direction);
                }, 10);

                if (this.infinity === false) {
                    if (+currentItem.getAttribute('data-id') === 0) {
                        return false;
                    }
                }

                setTimeout(() => {
                    this.items.forEach((item) => {
                        if (+item.getAttribute('data-id') === idCloneItem) {
                            clone = item.cloneNode(true);
                        }
                    });

                    if (this.infinity !== false) {
                        this.lay.prepend(clone);
                        this.items.unshift(clone);
                        this.left -= this.getWidthStep(cur);
                        this.lay.style.left = `${this.left}px`;
                    }

                    this.lay.style.transition = `0s`;
                    if (removeChild && this.infinity !== false) {
                        removeChild.parentNode.removeChild(removeChild);
                    }
                    this.flagEnd = true;
                    document.dispatchEvent(
                        new CustomEvent('create-slider-item', {
                            detail: { id: this.id, type: 'clone' },
                        }),
                    );
                }, 510);
                break;
            default:
                break;
        }

        return true;
    }

    setInclude(currentId) {
        if (this.infinity !== true) {
            this.items.forEach((item) => {
                const id = +item.getAttribute('data-id');
                item.removeAttribute('data-include');
                item.removeAttribute('data-state');

                if (id >= currentId && id < currentId + this.includeWindow) {
                    item.setAttribute('data-include', '');
                }

                if (id < currentId) {
                    item.setAttribute('data-state', 'before');
                }
                if (id >= currentId + this.includeWindow) {
                    item.setAttribute('data-state', 'after');
                }
            });
        }
    }

    setCurrentSlid(id) {
        if (this.infinity === false) {
            this.move = -((+id - 1) * this.widthItem);
            this.items.forEach((item) => {
                if (+item.getAttribute('data-id') === +id - 1) {
                    item.setAttribute('data-current', true);
                } else {
                    item.removeAttribute('data-current');
                }
            });
            document.dispatchEvent(
                new CustomEvent('change-slider', {
                    detail: {
                        id: this.id,
                        type: 'change',
                        current: +id - 1,
                        data: this.data,
                    },
                }),
            );
            if (this.buttons) {
                if (this.buttons.prev) {
                    this.buttons.prev.removeAttribute('data-disable');
                    if (+id - 1 === 0) {
                        this.buttons.prev.setAttribute('data-disable', true);
                    }
                }
                if (this.buttons.next) {
                    this.buttons.next.removeAttribute('data-disable');
                    if (+id - 1 + this.includeWindow === this.length) {
                        this.buttons.next.setAttribute('data-disable', true);
                    }
                }
            }
            setTimeout(() => {
                this.lay.style.transition = this.transition;
                this.moveLay(this.move);
            }, 10);
        }
    }

    destroy() {
        this.lay.removeEventListener('touchstart', this.touchStart);
        this.slider.removeEventListener('touchmove', this.touchMove);
        this.slider.removeEventListener('touchend', this.touchEnd);

        if (this.buttons) {
            ['prev', 'next'].forEach((key) => {
                const button = this.buttons[key];

                if (button) {
                    button.onclick = undefined;
                }
            });
        }
    }
}
