import React from 'react';

import getFormatPhone from '@functions/getFormatPhone.ts';

import { BlockT } from '../types.ts';

const StatusSmzNumbers: BlockT = function () {
    const savedPhone = getFormatPhone({ phone: this.state.savedPhone! });
    const fnsPhone = getFormatPhone({ phone: this.state.fnsPhone! });

    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__fnsLogo _col">
                    <img
                        src={require('../../../../img/fns-logo.svg').default}
                        alt=""
                        className="join__fnsLogoIcon"
                    />
                </div>
                <div className="join__blockTitle">
                    Номер телефона
                    <br />
                    не совпадает
                </div>
                <div className="join__blockText">
                    Номер телефона <b className="_noWrap">{savedPhone}</b> при подключении
                    не&nbsp;совпадает с&nbsp;номером телефона <b className="_noWrap">{fnsPhone}</b>,
                    привязанным в&nbsp;«Мой налог»
                </div>
                <div className="join__blockText _marginBottom">
                    <b>Выберите подходящий сценарий:</b>
                </div>

                <div className="join__action _col">
                    <p className="join__actionSupport">СЦЕНАРИЙ 1</p>
                    <div className="join__actionTitle">
                        Актуальный номер — <b>{savedPhone}</b>
                    </div>
                    <div className="join__blockText _marginBottom">
                        Если ваш актуальный номер телефона{' '}
                        <b className="_noWrap _link">{savedPhone}</b>, вам необходимо изменить номер
                        в&nbsp;приложении «Мой налог». После этого нажать на&nbsp;кнопку «Проверить
                        ещё раз»
                    </div>
                    {this.renderButton({
                        name: 'status-smz-check',
                        text: 'Проверить ещё раз',
                        className: '_notTop',
                    })}
                </div>
                <div className="join__action _col">
                    <p className="join__actionSupport">СЦЕНАРИЙ 2</p>
                    <div className="join__actionTitle">
                        Актуальный номер — <b>{fnsPhone}</b>
                    </div>
                    <div className="join__blockText _marginBottom">
                        Если ваш актуальный номер телефона{' '}
                        <b className="_noWrap _link">{fnsPhone}</b>, нажмите на кнопку «Изменить
                        номер». Мы&nbsp;сохраним его
                    </div>
                    {this.renderButton({
                        name: 'status-smz-number',
                        text: 'Изменить номер',
                        className: '_notTop',
                    })}
                </div>
            </div>
        </>
    );
};

export default StatusSmzNumbers;
