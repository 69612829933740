import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import changePage from '@functions/changePage.ts';
import getHeaders from '@functions/getHeaders.ts';
import { dispatcher } from '@redux/redux.ts';
import getListItems from '@requests/getListItems.ts';

import Animate from '../../../components/Animate.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import ListScroll from '../../../components/ListScroll.jsx';
import Loader from '../../../components/Loader.jsx';
import CorporationPreview from '../../../components/app/corporations/CorporationPreview.jsx';
import Table from '../../../components/crm/manual/Table.jsx';

import removeTransition from '../../../functions/removeTransition.ts';

class CorporationsInner extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderCard = this.renderCard.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.changeCorporations = this.changeCorporations.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 8;

    renderCard({ item: corporation }) {
        return (
            <div className="orders__pagePreview">
                <div className="orders__pagePreviewInner">
                    <CorporationPreview
                        {...corporation}
                        accept={this.accept.bind(this, corporation._id)}
                    />
                </div>
            </div>
        );
    }

    async accept(id) {
        const { user } = this.props;
        let response;

        try {
            response = await axios.patch(
                `${process.env.REACT_APP_API}/corporation-app`,
                {
                    id,
                    action: 'accept',
                },
                { headers: getHeaders() },
            );
        } catch (error) {
            await this.handlerLoading(undefined);

            return;
        }

        const { success, data } = response.data;

        if (success) {
            await dispatcher({ type: 'user', data: { ...user, idOfCurrentCorporation: data.id } });

            changePage({ pageName: 'index' });
        }
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    async getItems(isForce = false) {
        const { name } = this.props;
        const query = this.getQueryForRequest();

        query.params.push({ key: 'type', value: name });

        const { items, isLimit } = await getListItems({
            url: 'corporation-app',
            query: [{ key: 'inList', value: 'true' }],
        });

        await this.setItems(items, isForce, isLimit);

        this.setState({ isInit: true });

        removeTransition({ item: '.orders__pagePreview', isCurrent: true });
    }

    changeCorporations() {
        this.updateItems();
    }

    componentDidMount() {
        this.getItems();

        document.addEventListener('changeCorporations', this.changeCorporations);
    }

    componentWillUnmount() {
        document.removeEventListener('changeCorporations', this.changeCorporations);
    }

    render() {
        const {
            isInit,
            isDisabledScroll,
            items = [],
            isLimit,
            isShowLoaderList = false,
            isLoadingFilter = false,
            counterUpdate,
        } = this.state;

        return (
            <div
                ref={this.parent}
                className={`orders__pageInner ${isInit && !isLoadingFilter ? '_init' : ''}`}
            >
                <Animate className="orders__pageLoader _loader" isShow={!isInit || isLoadingFilter}>
                    <div className="orders__pageLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate
                    className="orders__pageScrollLoader _loaderScroll"
                    isShow={isShowLoaderList}
                >
                    <div className="orders__pageScrollLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate className="orders__pageEmpty" isShow={isInit && items.length === 0}>
                    <div className="empty _col _notBack _block">
                        <div className="empty__inner">
                            <div className="empty__title">На этом пока всё</div>
                            <p className="empty__content">Доступных компаний нет</p>
                        </div>
                    </div>
                </Animate>
                <div className="orders__pageBox" key={counterUpdate}>
                    <ListScroll
                        isInit={isInit}
                        getParent={() => this.parent.current?.querySelector('.orders__pageBox')}
                        callback={this.getMoreItems}
                        startCounter={this.stepCounter}
                        stepCounter={this.stepCounter}
                        maxCounter={Infinity}
                        lengthCurrent={items.length}
                        handlerLoaderList={this.handlerLoaderList}
                        isLimit={isLimit}
                        isDisabledScroll={isDisabledScroll || !isInit}
                    >
                        <ListAbsoluteMain
                            className="orders__pagePreviews _col"
                            items={items}
                            renderItem={this.renderCard}
                            classNameItem="orders__pagePreview"
                            prop="_id"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isSmoothShow={true}
                        />
                    </ListScroll>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(CorporationsInner);

CorporationsInner.propTypes = {
    user: PropTypes.object,
    name: PropTypes.string,
};
