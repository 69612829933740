import React from 'react';

import Button from '@components/button/Button.tsx';
import Icon from '@components/icon/Icon.tsx';

import I from '../types.ts';

const renderButton: I['renderButton'] = function ({
    text,
    name,
    onClick,
    reverseIcon,
    className = '',
}) {
    const { loadingKey } = this.state;

    return (
        <div className={`join__blockButton ${className}`}>
            <Button
                className="_main _shadow _bigSize"
                onClick={() => {
                    if (onClick) {
                        onClick({ name });
                    } else {
                        this.stepHandler({ name });
                    }
                }}
                loading={loadingKey === name}
            >
                {reverseIcon && (
                    <i className="_arrowPrev">
                        <Icon name="arrow-prev" />
                    </i>
                )}
                {text}
                {!reverseIcon && (
                    <i className="_arrowNext">
                        <Icon name="arrow-next" />
                    </i>
                )}
            </Button>
        </div>
    );
};

export default renderButton;
