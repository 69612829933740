import React from 'react';

import StatusIpForm from '../components/StatusIpForm';
import StatusSmzAccess from '../components/StatusSmzAccess';
import StatusSmzCheck from '../components/StatusSmzCheck';
import StatusSmzForm from '../components/StatusSmzForm';
import StatusSmzInstruction from '../components/StatusSmzInstruction';
import StatusSmzNumbers from '../components/StatusSmzNumbers';
import StatusStart from '../components/StatusStart';

import JoinI from '../types';

const statusBlocks = {
    statusStart: {
        render(this: JoinI) {
            return <>{StatusStart.call(this)}</>;
        },
    },
    statusIpForm: {
        render(this: JoinI) {
            return <>{StatusIpForm.call(this)}</>;
        },
    },
    statusSmzInstruction: {
        render(this: JoinI) {
            return <>{StatusSmzInstruction.call(this)}</>;
        },
    },
    statusSmzAccess: {
        render(this: JoinI) {
            return <>{StatusSmzAccess.call(this)}</>;
        },
    },
    statusSmzForm: {
        render(this: JoinI) {
            return <>{StatusSmzForm.call(this)}</>;
        },
    },
    statusSmzCheck: {
        render(this: JoinI) {
            return <>{StatusSmzCheck.call(this)}</>;
        },
    },
    statusSmzNumbers: {
        render(this: JoinI) {
            return <>{StatusSmzNumbers.call(this)}</>;
        },
    },
} as const;

export default statusBlocks;
