import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import getAppNotifications from '../../../requests/getAppNotifications';

import ListScroll from '../../../components/ListScroll.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';

import Table from '../../../components/crm/manual/Table.jsx';
import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';
import LinkBtn from '../../../components/app/LinkBtn.jsx';

import removeTransition from '../../../functions/removeTransition.ts';
import NotificationPreview from '../../../components/app/notifications/NotificationPreview.jsx';

import getHeaders from '../../../functions/getHeaders';
import checkAuth from '../../../functions/app/checkAuth';

class NotificationsInner extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderCard = this.renderCard.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.changePays = this.changePays.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.setCompletedNotifications = this.setCompletedNotifications.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 15;

    renderCard({ item, prop: id }) {
        const { loadingKey } = this.state;
        const { name } = this.props;

        return (
            <div className="pays__pagePreview">
                <div className="pays__pagePreviewInner _col">
                    {id === 'read' ? (
                        <>
                            <div className="pays__pagePreviewRead">
                                <LinkBtn
                                    className="_right"
                                    showLoader={loadingKey === 'all'}
                                    handler={this.setCompletedNotifications.bind(this, 'all')}
                                >
                                    Прочитать все
                                </LinkBtn>
                            </div>
                        </>
                    ) : (
                        <>
                            <NotificationPreview
                                setCompletedNotifications={this.setCompletedNotifications.bind(
                                    this,
                                    item._id,
                                )}
                                notificationsType={name}
                                {...item}
                                name={item.key}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getItems(isForce = false) {
        const { name } = this.props;
        const query = this.getQueryForRequest();

        query.params.push({ key: 'type', value: name });

        return new Promise((resolve) => {
            getAppNotifications(query).then(({ notifications, isLimit }) => {
                if (!this.state.isInit) {
                    setTimeout(() => {
                        this.setState({ isInit: true });
                    }, 300);
                }

                this.setItems(notifications, isForce, isLimit).then(() => {
                    removeTransition({ item: '.pays__pagePreview', isCurrent: true });

                    resolve();
                });
            });
        });
    }

    changePays() {
        this.getItems(true);
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    setCompletedNotifications(id) {
        return new Promise((resolve, reject) => {
            this.handlerLoading(id).then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/app/notifications`,
                        { id },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                this.updateItems();

                                checkAuth();

                                resolve();
                            } else {
                                reject();
                            }

                            this.handlerLoading(null);
                        },
                        () => null,
                    );
            });
        });
    }

    getItemsOrder() {
        const { items = [] } = this.state;
        const { name } = this.props;

        if (items.length === 0) {
            return [];
        }

        return [...(name === 'new' ? [{ _id: 'read' }] : []), ...items];
    }

    handlerSocket({ detail }) {
        const { name, data } = detail;

        if (name === 'pays') {
            const { fields } = data;

            if (fields.item) {
                this.updateItem({ id: fields.item._id, fields: fields.item });
            }

            if (fields?.isUpdatePays) {
                this.changePays();
            }
        }
    }

    componentDidMount() {
        this.getItems();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changePays', this.changePays);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changePays', this.changePays);
    }

    render() {
        const {
            isInit,
            isDisabledScroll,
            isLimit,
            isShowLoaderList = false,
            updatedItemKey,
            isLoadingFilter = false,
        } = this.state;
        const items = this.getItemsOrder();

        return (
            <div
                ref={this.parent}
                className={`pays__pageInner ${isInit && !isLoadingFilter ? '_init' : ''}`}
            >
                <Animate className="pays__pageLoader _loader" isShow={!isInit || isLoadingFilter}>
                    <div className="pays__pageLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate className="pays__pageScrollLoader _loaderScroll" isShow={isShowLoaderList}>
                    <div className="pays__pageScrollLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate className="pays__pageEmpty _loader" isShow={isInit && items.length === 0}>
                    <div className="empty _col _notBack _block">
                        <div className="empty__inner">
                            <div className="empty__title">На этом пока всё</div>
                            <p className="empty__content">У вас нет уведомлений</p>
                        </div>
                    </div>
                </Animate>
                <div className="pays__pageBox">
                    <ListScroll
                        isInit={isInit}
                        getParent={() => this.parent.current?.querySelector('.pays__pageBox')}
                        callback={this.getMoreItems}
                        startCounter={this.stepCounter}
                        stepCounter={this.stepCounter}
                        maxCounter={Infinity}
                        lengthCurrent={items.length}
                        handlerLoaderList={this.handlerLoaderList}
                        isLimit={isLimit}
                        isDisabledScroll={isDisabledScroll || !isInit || isLoadingFilter}
                    >
                        <ListAbsoluteMain
                            className="pays__pagePreviews _col"
                            items={items}
                            renderItem={this.renderCard}
                            classNameItem="pays__pagePreview"
                            prop="_id"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isSmoothShow={true}
                            keyUpdateItem={updatedItemKey}
                        />
                    </ListScroll>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(NotificationsInner);

NotificationsInner.propTypes = {
    user: PropTypes.object,
    name: PropTypes.string,
};
