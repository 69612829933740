import axios from 'axios';

import connectSockets from '@functions/connectSockets.js';
import { setCookie } from '@functions/handlerCookies.js';
import handlerLoading from '@functions/handlerLoading.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../../types.ts';

const phoneHandler = async function (this: I, code?: string) {
    const { joinScript } = this.state;
    const model = this.state.model!;
    let response;

    await handlerLoading.call(this, code ? 'code' : 'phone');

    try {
        response = await axios.post<
            {},
            {
                data: ReqResponseT<{
                    executorInfo?: any;
                    joinInfo?: any;
                    token?: string;
                    hash?: string;
                    id?: string;
                }>;
            }
        >(
            `${process.env.REACT_APP_API}/join`,
            {
                phone: model.phone,
                scriptLink: joinScript?.link,
                code,
            },
            { headers: {} },
        );
    } catch (error) {
        await handlerLoading.call(this, undefined);

        return;
    }

    await handlerLoading.call(this, undefined);

    const { success, data } = response.data;

    if (success) {
        const { token, executorInfo } = data;
        const rejectComment = executorInfo?.rejectComment as string;

        if (executorInfo) {
            const fields = executorInfo;

            fields['smz-phone'] = executorInfo?.phone;

            await this.init({ fields });
        }

        if (token) {
            setCookie('joinToken', token);

            connectSockets(true);
        }

        const { joinInfo } = data;

        const statusData = this.checkCurrentStep(joinInfo);

        if (!joinInfo) {
            statusData.status = code ? 'status' : 'code';
        }

        this.setState({ rejectComment, error: undefined });

        await this.setStep(statusData.status, statusData.currentStatusStep);

        const { hash, id } = data;

        if (hash && id) {
            localStorage.setItem('joinAuthHash', hash);
            localStorage.setItem('joinAuthId', id);
        }
    } else {
        this.setState({ error: data.error });

        return Promise.reject();
    }
};

export default phoneHandler;
