import I from '../types.ts';

const handlerSocket: I['handlerSocket'] = function ({ detail }) {
    const { name, data } = detail;

    if (name === 'join') {
        const { status, executorInfo } = data;

        if (status) {
            this.setStep(status);
        }

        if (executorInfo) {
            if (executorInfo.rejectComment) {
                this.setState({ rejectComment: executorInfo.rejectComment as string });
            }

            this.init({ fields: executorInfo });
        }
    }
};

export default handlerSocket;
