import axios from 'axios';
import getHeaders from './getHeaders';

const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
};

export default async function checkWebPush({ fromAuth = false }) {
    if (process.env.REACT_APP_ENV === 'local') {
        return Promise.reject();
    }

    if (!('serviceWorker' in navigator)) {
        return Promise.reject();
    }

    if (!('PushManager' in window)) {
        return Promise.reject();
    }

    let registration;

    try {
        registration = await navigator.serviceWorker.ready;
    } catch (error) {
        return Promise.reject();
    }

    if (!registration.pushManager) {
        return Promise.reject();
    }

    let existingSubscription;

    try {
        existingSubscription = await registration.pushManager.getSubscription();
    } catch (error) {
        return Promise.reject();
    }

    const getSubscription = async () => {
        if (existingSubscription) {
            return existingSubscription;
        }

        if (fromAuth) {
            return null;
        }

        const publicKey =
            'BGCzxB_wmh_z_jXMrF8OYUUIP8svfCWe-NAKrhsgyB5cpjA06JhXT7-R3qCRqX2BEvvB5NitImEnBrCAJNbJUPk';
        const convertedVapidKey = urlBase64ToUint8Array(publicKey);

        try {
            const subscription = await registration.pushManager.subscribe({
                applicationServerKey: convertedVapidKey,
                userVisibleOnly: true,
            });

            return subscription;
        } catch (error) {
            return null;
        }
    };

    const subscription = await getSubscription();

    if (subscription) {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/web-push`,
                {
                    subscription,
                },
                { headers: getHeaders() },
            );

            const { success } = response.data;

            if (success) {
                return true;
            }

            return Promise.reject();
        } catch (error) {
            return Promise.reject();
        }
    }

    return true;
}
