import React from 'react';

import { BlockT } from '../types.ts';

const Start: BlockT = function () {
    const { joinScript } = this.state;

    return (
        <>
            <div className="join__blockInner _col">
                <img
                    src={require('../../../../img/emodzi/victory-hand.png')}
                    alt=""
                    className="join__blockIcon"
                />
                <div className="join__blockTitle">
                    «{joinScript?.corporationName}» приглашает вас к&nbsp;сотрудничеству
                </div>
                <div className="join__blockText">Через CRM LIVECARGO</div>
                {this.renderButton({ name: 'start', text: 'Подключиться' })}
                {this.renderTelegram()}
            </div>
        </>
    );
};

export default Start;
