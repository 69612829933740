import { getCookie } from '@functions/handlerCookies.js';

import I from '../types.ts';

const visibilityDocChange: I['visibilityDocChange'] = function ({ detail: { isActive } }) {
    const { currentStep } = this.state;

    if (isActive && !['phone', 'code'].includes(currentStep) && getCookie('joinToken')) {
        this.getInfo(false);
    }
};

export default visibilityDocChange;
