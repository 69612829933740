import React from 'react';

import I from '../types.ts';

const renderTabs: I['renderTabs'] = function () {
    let { currentStep } = this.state;

    if (currentStep === 'reject') {
        currentStep = 'check';
    }

    const isTabsHide = ['start', 'complete'].includes(currentStep);

    return (
        <div className={`join__tabs ${isTabsHide ? '_hide' : ''}`}>
            <div className="join__tabsInner _row">
                {this.tabsOrder
                    .filter((name) => name !== 'complete')
                    .map((name, key) => {
                        const tab = this.tabs[name];
                        const currentKey = this.tabsOrder.indexOf(
                            currentStep as (typeof this.tabsOrder)[number],
                        );

                        const isComplete = key < currentKey;

                        return (
                            <div className="join__tabWrapper" key={name} data-name={name}>
                                <div
                                    className={`join__tab _${name} ${
                                        currentStep === name ? '_current' : ''
                                    } ${isComplete ? '_complete' : ''}`}
                                >
                                    <div className="join__tabInner _row">
                                        {name !== 'start' && (
                                            <div className="join__tabCounter _col">{key}</div>
                                        )}
                                        {tab.content}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default renderTabs;
