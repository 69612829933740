import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import AnimateChangeUp from '../../../components/AnimateChangeUp.jsx';
import Button from '../../../components/Button.jsx';
import getHeaders from '../../../functions/getHeaders';
import setSpacesInText from '../../../functions/setSpacesInText';
import getFormatPrice from '../../../functions/getFormatPrice';

import handlerPopup from '../../../functions/app/handlerPopup';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import handlerFns from '../../../functions/app/handlerFns';

class Fns extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderDynamicBlock = this.renderDynamicBlock.bind(this);
    }

    errors = {
        'account-not-adding':
            'Налогоплательщик не привязан к компании Livecargo CRM в приложении «Мой налог»',
        'account-not-found': 'Налогоплательщик не имеет статус НПД.',
        'fns-error': 'Ошибка сервиса ФНС',
    };

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    async handlerDocs({ key }) {
        this.setState({ docsError: null });

        await this.handlerLoading(key);

        axios
            .patch(
                `${process.env.REACT_APP_API}/executor`,
                {
                    action: 'handler-fns-docs',
                    type: key,
                },
                { headers: getHeaders() },
            )
            .then((res) => {
                const { success, data } = res.data;

                if (success) {
                    const { fileName } = data;

                    window.open(
                        `${process.env.REACT_APP_STATIC}/executor-docs/${fileName}`,
                        '_blank',
                    );
                } else {
                    const { message } = data;

                    if (message === 'Account not adding for company') {
                        this.setState({
                            docsError: 'account-not-adding',
                        });
                    } else if (message === 'Account not found') {
                        this.setState({
                            docsError: 'account-not-found',
                        });
                    } else {
                        this.setState({
                            docsError: 'fns-error',
                        });
                    }
                }

                this.handlerLoading(null);
            });
    }

    getDynamicBlocks() {
        const { user } = this.props;
        const { isJurStatusActive } = user;

        if (!isJurStatusActive) {
            return [];
        }

        return [{ key: 'active' }];
    }

    renderDynamicBlock() {
        const { loadingKey, docsError } = this.state;
        const { user } = this.props;

        return (
            <div className="settings__fnsDynamicsBlock">
                <div className="settings__fnsBlock">
                    <div className="settings__fnsHead _row">
                        <div className="settings__fnsHeadTitle">Информация по НП</div>
                    </div>
                    <div className="settings__fnsContent _foot">
                        <div className="settings__fnsData _row">
                            <div className="settings__fnsDataText _support">Сумма начисления</div>
                            <div className="settings__fnsDataText">
                                {getFormatPrice(user.fnsAccrialsAndDebts?.amount || 0)}₽
                            </div>
                        </div>
                        <div className="settings__fnsData _row">
                            <div className="settings__fnsDataText _support">
                                Сумма задолженности
                            </div>
                            <div className="settings__fnsDataText">
                                {getFormatPrice(user.fnsAccrialsAndDebts?.debt || 0)}₽
                            </div>
                        </div>
                        <div className="settings__fnsData _row">
                            <div className="settings__fnsDataText _support">Сумма переплаты</div>
                            <div className="settings__fnsDataText">
                                {getFormatPrice(user.fnsAccrialsAndDebts?.overpayment || 0)}₽
                            </div>
                        </div>
                        <div className="settings__fnsData _row">
                            <div className="settings__fnsDataText _support">Бонус</div>
                            <div className="settings__fnsDataText">
                                {getFormatPrice(user.fnsAccrialsAndDebts?.bonus || 0)}₽
                            </div>
                        </div>
                    </div>
                </div>
                <div className="settings__fnsBlock">
                    <div className="settings__fnsHead _row">
                        <div className="settings__fnsHeadTitle">Справки</div>
                    </div>
                    <div className="settings__fnsContent _foot">
                        <div className="settings__fnsButton">
                            <Button
                                className="_blue"
                                showLoader={loadingKey === 'registration'}
                                onClick={() => this.handlerDocs({ key: 'registration' })}
                            >
                                Справка о постановке на учет
                            </Button>
                        </div>
                        <div className="settings__fnsButton">
                            <Button
                                className="_blue"
                                showLoader={loadingKey === 'income'}
                                onClick={() => this.handlerDocs({ key: 'income' })}
                            >
                                Справка о доходах
                            </Button>
                        </div>
                        <AnimateChangeUp
                            className="settings__fnsError"
                            renderKey={docsError}
                            parentStyles={['height']}
                            paramsParent={{ width: true }}
                        >
                            <div
                                className="settings__fnsErrorInner"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(this.errors[docsError]),
                                }}
                            />
                        </AnimateChangeUp>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { loadingKey, error } = this.state;
        const { user } = this.props;
        const { isJurStatusActive, isJurStatusProcess } = user;

        return (
            <div className="settings _col">
                <div className="settings__fns">
                    <div className="settings__fnsBlock">
                        <div className="settings__fnsHead _row _logo">
                            <img
                                src={require('../../../img/fns-logo.svg').default}
                                alt=""
                                className="settings__fnsHeadLogo"
                            />
                            <div className="settings__fnsHeadTitle">
                                Подключение <br />
                                в приложении
                                <br />
                                «Мой Налог»
                            </div>
                        </div>
                        <div className="settings__fnsContent">
                            <div className="settings__fnsStatus _row">
                                <p className="settings__fnsStatusSupport">
                                    Статус
                                    <br />
                                    подключения:
                                </p>
                                <div
                                    className={`settings__fnsStatusItem ${
                                        isJurStatusActive ? '_active' : ''
                                    } ${isJurStatusProcess ? '_process' : ''}`}
                                >
                                    <AnimateChangeUp
                                        className="settings__fnsStatusItemInner"
                                        renderKey={`${isJurStatusActive}${isJurStatusProcess}`}
                                    >
                                        <>
                                            {isJurStatusActive
                                                ? 'Подключено'
                                                : isJurStatusProcess
                                                ? 'На подключении'
                                                : 'Не подключено'}
                                        </>
                                    </AnimateChangeUp>
                                </div>
                            </div>
                        </div>
                        <div className="settings__fnsFoot">
                            <div className="settings__fnsButton">
                                <AnimateChangeUp
                                    className="settings__fnsButtonInner"
                                    renderKey={`${isJurStatusActive}${isJurStatusProcess}`}
                                    isNotParams={true}
                                >
                                    {isJurStatusActive ? (
                                        <Button
                                            className="_alertLight _notShadow"
                                            showLoader={loadingKey === 'saveFns'}
                                            onClick={handlerFns.bind(this, {})}
                                        >
                                            Отключить «Мой налог»
                                        </Button>
                                    ) : isJurStatusProcess ? (
                                        <Button
                                            className="_main _notShadow"
                                            showLoader={loadingKey === 'saveFns'}
                                            onClick={handlerFns.bind(this, { isCheck: true })}
                                        >
                                            Проверить подключение
                                        </Button>
                                    ) : (
                                        <Button
                                            className="_main _notShadow"
                                            showLoader={loadingKey === 'saveFns'}
                                            onClick={handlerFns.bind(this, { isAdd: true })}
                                        >
                                            Подключить «Мой налог»
                                        </Button>
                                    )}
                                </AnimateChangeUp>
                            </div>
                            <AnimateChangeUp
                                className="settings__fnsError"
                                renderKey={error}
                                parentStyles={['height']}
                                paramsParent={{ width: true }}
                            >
                                <>
                                    <div
                                        className="settings__fnsErrorInner"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(this.errors[error]),
                                        }}
                                    />
                                    {error === 'account-not-found' && (
                                        <>
                                            <div className="settings__fnsErrorInner">
                                                Чтобы встать на учет, следуйте{' '}
                                                <div
                                                    className="settings__fnsErrorLink _click"
                                                    onClick={() => {
                                                        this.setState({ error: null });

                                                        handlerPopup({
                                                            name: 'appSmzInstructionPopup',
                                                            isShow: true,
                                                        });
                                                    }}
                                                >
                                                    инструкции
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            </AnimateChangeUp>
                        </div>
                    </div>
                    <ListAbsoluteMain
                        className="settings__fnsDynamics"
                        items={this.getDynamicBlocks()}
                        renderItem={this.renderDynamicBlock}
                        classNameItem="settings__fnsDynamicsBlock"
                        prop="key"
                        styles={['height']}
                        isNotParamsItem={true}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        pagesStore: state.pages,
    };
}

export default connect(mapStateToProps)(Fns);

Fns.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    pagesStore: PropTypes.object,
};
