import I from '../types.ts';

const checkCurrentStep: I['checkCurrentStep'] = function (info, ...props) {
    const { joinScript, currentStep, currentStatusStep } = this.state;

    let resultCurrentStep = props[0] || currentStep;
    let resultCurrentStatusStep = props[1] || currentStatusStep;

    if (info) {
        if (info.status) {
            resultCurrentStep = info.status;
        }

        if (resultCurrentStep === 'status') {
            if (joinScript?.executorOrganization === 'SMZ') {
                if (info.fnsStatus === 'process') {
                    resultCurrentStatusStep = 'statusSmzCheck';
                }

                if (info.fnsStatus === 'complete') {
                    resultCurrentStatusStep = 'statusSmzCheck';
                }
            }
        }
    }

    return {
        status: resultCurrentStep,
        currentStatusStep: resultCurrentStatusStep,
    };
};

export default checkCurrentStep;
