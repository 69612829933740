const fields = {
    phone: {
        type: 'input',
        reg: 'phone',
        inputSupport: 'Ваш номер телефона',
    },
    secondName: {
        type: 'input',
        inputSupport: 'Ваша фамилия',
    },
    firstName: {
        type: 'input',
        inputSupport: 'Ваше имя',
    },
    thirdName: {
        type: 'input',
        inputSupport: 'Ваше отчетство',
    },
    telegram: {
        type: 'input',
        inputSupport: 'Телеграм',
    },
    inn: {
        type: 'input',
        inputSupport: 'Ваш ИНН',
        regExp: /[^\d]/gi,
    },
    type: {
        type: 'select',
        inputSupport: 'Категория',
        selectTextKey: 'typeName',
        selectList: 'executorTypes',
        selectProps: { name: 'typeName' },
    },
    country: {
        type: 'select',
        inputSupport: 'Гражданство',
        selectTextKey: 'countryName',
        selectList: 'countries',
        selectProps: { name: 'countryName' },
        defaultQuery: (model: any, data: any) =>
            ['SMZ', 'IP'].includes(data?.data?.organization)
                ? [
                      { key: 'types', value: 'RUS' },
                      { key: 'types', value: 'EAES' },
                  ]
                : [],
    },
    town: {
        type: 'select',
        inputSupport: 'Город',
        selectTextKey: 'townName',
        selectList: 'cities',
        selectProps: { name: 'townName' },
    },
    cardNumber: {
        type: 'input',
        inputSupport: 'Номер банковской карты',
        regExp: /[^\d]/gi,
    },
    bic: {
        type: 'input',
        inputSupport: 'Номер БИК',
        regExp: /[^\d]/gi,
    },
    accountNumber: {
        type: 'input',
        inputSupport: 'Номер счёта',
        regExp: /[^\d]/gi,
    },
    addressatName: {
        type: 'input',
        inputSupport: 'ФИО получателя',
    },
    'smz-phone': {
        type: 'input',
        reg: 'phone',
        inputSupport: 'Введите ваш номер телефона',
    },
    'smz-inn': {
        type: 'input',
        inputSupport: 'Введите ваш ИНН',
        regExp: /[^\d]/gi,
    },
} as const;

export default fields;
