import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@components/avatar/Avatar.tsx';
import changeCorporation from '@functions/app/changeCorporation.js';

import toSignContract from '../../../functions/app/toSignContract';

import Button from '../../Button.jsx';

class CorporationPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.signContract = this.signContract.bind(this);
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    async signContract() {
        const { _id } = this.props;

        await this.handlerLoading('sign');

        toSignContract({ corporationId: _id }).then(() => {
            this.handlerLoading(null);
        });
    }

    componentDidMount() {
        this.setState({ user: this.props.user });
    }

    render() {
        const { loadingKey, user } = this.state;
        const { _id, accept, name, logo, inn, status } = this.props;
        let statusContent = '';

        if (status === 'active') {
            statusContent = 'Вы подключены';
        } else if (status === 'invite') {
            statusContent = 'Вас приглашают';
        } else if (status === 'inactive') {
            statusContent = 'Вы отключены';
        }

        return (
            <div className="corporationPreview">
                <div className="corporationPreview__inner">
                    <div className="corporationPreview__content _row">
                        <Avatar
                            className="corporationPreview__logo"
                            image={logo?.fullSrc}
                            firstSymb={name?.[0]}
                        />
                        <div className="corporationPreview__info _col">
                            <div className="corporationPreview__name">{name}</div>
                            <div className="corporationPreview__description">ИНН: {inn}</div>
                            {statusContent && (
                                <div className={`corporationPreview__status _${status}`}>
                                    {statusContent}
                                </div>
                            )}
                        </div>
                    </div>
                    {status === 'active' && user?.idOfCurrentCorporation !== _id && (
                        <div className="corporationPreview__button">
                            <Button
                                className="_blue"
                                onClick={() => {
                                    changeCorporation({ id: _id });
                                }}
                            >
                                Перейти в компанию
                            </Button>
                        </div>
                    )}
                    {['free', 'invite'].includes(status) && (
                        <div className="corporationPreview__button">
                            <Button className="_blue" onClick={accept}>
                                Подключиться к компании
                            </Button>
                        </div>
                    )}
                    {status === 'not-sign' && (
                        <div className="corporationPreview__button">
                            <Button
                                className="_main"
                                onClick={this.signContract}
                                showLoader={loadingKey === 'sign'}
                            >
                                Подписать договор
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(CorporationPreview);

CorporationPreview.propTypes = {
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.object,
    inn: PropTypes.string,
    status: PropTypes.string,
    accept: PropTypes.func,
};
