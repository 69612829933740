import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import getFormatedDate from '../../../functions/getFormatedDate';
import setSpacesInText from '../../../functions/setSpacesInText';
import Button from '../../Button.jsx';
import changePage from '../../../functions/changePage';
import joinToCorporation from '../../../functions/app/joinToCorporation';

class Notifications extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.joinToCorporation = this.joinToCorporation.bind(this);
        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appNotificationsPopup', isShow: false });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    joinToCorporation() {
        const { appNotificationsPopup } = this.props;
        const { corporationId } = appNotificationsPopup;

        this.handlerLoading('save').then(() => {
            joinToCorporation({ id: corporationId }).then(
                () => {
                    this.hide();
                    this.handlerLoading(null);
                },
                (error) => {
                    if (error.message === 'Corporation already create') {
                        this.hide();
                        changePage({ href: 'corporations-main' });
                    }

                    this.handlerLoading(null);
                },
            );
        });
    }

    componentDidMount() {
        const { setCompletedNotifications, appNotificationsPopup } = this.props;
        const { status } = appNotificationsPopup;

        if (status === 'new') {
            setCompletedNotifications();
        }

        super.componentDidMount();
    }

    render() {
        const { loadingKey } = this.state;
        const { appNotificationsPopup, user } = this.props;
        const { typeTitle, title, content, date, type, data, key } = appNotificationsPopup;

        return (
            <div ref={this.parent} className="appPopup _col _notification">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__notificationHead">
                                <div className="appPopup__notificationHeadTitle">{typeTitle}</div>
                                <div className="appPopup__notificationHeadDate">
                                    {getFormatedDate({ date: new Date(date), type: 'full' })}
                                </div>
                            </div>
                            <p
                                className="appPopup__description _bold"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                            ></p>
                            <p
                                className="appPopup__description"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(content) }}
                            ></p>
                            {data?.fnsType === 'unregistered' && (
                                <>
                                    <p className="appPopup__description">
                                        Чтобы встать на учет, следуйте{' '}
                                        <span
                                            className="appPopup__descriptionLink"
                                            onClick={() => {
                                                handlerPopup({
                                                    name: 'appSmzInstructionPopup',
                                                    isShow: true,
                                                    callback: this.hide,
                                                });
                                            }}
                                        >
                                            инструкции
                                        </span>
                                    </p>
                                </>
                            )}
                            {data?.fnsType === 'unbound' && (
                                <div className="appPopup__button _margin">
                                    <Button
                                        onClick={() => {
                                            this.hide();

                                            changePage({ href: 'settings-fns' });
                                        }}
                                    >
                                        Подключиться
                                    </Button>
                                </div>
                            )}
                            {type === 'pay' && user.pays.find((pay) => pay._id === data.payId) && (
                                <div className="appPopup__button _margin">
                                    <Button
                                        onClick={() => {
                                            handlerPopup({
                                                name: 'appSignPayPopup',
                                                isShow: true,
                                                payId: data.payId,
                                                prevHide: this.hide,
                                                callback: () => {
                                                    this.hide();

                                                    changePage({ href: 'pays-all' });
                                                },
                                            });
                                        }}
                                    >
                                        Подписать акт
                                    </Button>
                                </div>
                            )}
                            {type === 'system' && key === 'inactiveContract' && (
                                <div className="appPopup__button _margin">
                                    <Button
                                        showLoader={loadingKey === 'save'}
                                        onClick={this.joinToCorporation}
                                    >
                                        Подключиться снова
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
        appNotificationsPopup: state.appNotificationsPopup,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Notifications);

Notifications.propTypes = {
    windowHeight: PropTypes.number,
    hideCallback: PropTypes.func,
    appNotificationsPopup: PropTypes.object,
    user: PropTypes.object,
};
