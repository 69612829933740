import axios from 'axios';

import handlerLoading from '@functions/handlerLoading.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../../types.ts';

const statusSmzHandler = async function (
    this: I,
    action?: 'add' | 'check' | 'number',
    updateNumber?: boolean,
) {
    const { model } = this.state;
    let response;

    await handlerLoading.call(this, `status-smz-${action}`);

    const resultAction = action === 'number' ? 'check' : action;

    try {
        response = await axios.patch<
            {},
            {
                data: ReqResponseT<{
                    joinInfo?: any;
                    executorInfo?: any;
                    fnsStatus?: any;
                    fnsPhone?: string;
                    numbersIsDifferent?: boolean;
                }>;
            }
        >(
            `${process.env.REACT_APP_API}/join`,
            {
                fields: {
                    phone: model!['smz-phone'],
                    inn: model!['smz-inn'],
                },
                action: resultAction,
                checkOrganization: true,
                updateNumber,
            },
            this.getHeaders(),
        );
    } catch (error) {
        return;
    }

    const { success, data } = response.data;

    if (success) {
        const { joinInfo, executorInfo, fnsPhone, numbersIsDifferent } = data;

        if (executorInfo) {
            await this.init({ fields: this.getInitFields(executorInfo) });
        }

        if (fnsPhone) {
            this.setState({ fnsPhone, savedPhone: this.state.model!.phone });
        }

        if (numbersIsDifferent) {
            this.setStep('status', 'statusSmzNumbers');
        } else if (joinInfo) {
            const statusData = this.checkCurrentStep(joinInfo);

            this.setStep(statusData.status, statusData.currentStatusStep);
        }
    } else {
        const { error, message, fnsStatus } = data;

        if (message === 'Account not adding for company' && fnsStatus === null) {
            this.setStep('status', 'statusSmzForm');
        }

        this.setState({ error });
    }

    await handlerLoading.call(this, undefined);
};

export default statusSmzHandler;
