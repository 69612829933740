import axios from 'axios';
import getHeaders from '../getHeaders';
import changePage from '../changePage';

export default function joinToCorporation({ id }) {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_API}/app/corporations`,
                { id },
                { headers: getHeaders() },
            )
            .then((res) => {
                const { success, data } = res.data;

                if (success) {
                    changePage({ href: 'corporations/main' });

                    document.dispatchEvent(new CustomEvent('changeCorporations'));

                    resolve();
                } else {
                    reject(data);
                }
            });
    });
}
