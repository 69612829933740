import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '../../Animate.jsx';
import AuthCode from './AuthCode.jsx';
import Balance from './Balance.jsx';
import Contract from './Contract.jsx';
import FnsActivateInfo from './FnsActivateInfo.jsx';
import InstructionApplication from './InstructionApplication.jsx';
import JoinCorporation from './JoinCorporation.jsx';
import ListPopup from './ListPopup.jsx';
import Logout from './Logout.jsx';
import NetworkDisconnect from './NetworkDisconnect.jsx';
import NewVersion from './NewVersion.jsx';
import Notifications from './Notifications.jsx';
import PayCancel from './PayCancel.jsx';
import PayInfo from './PayInfo.jsx';
import ServerNotAvailable from './ServerNotAvailable.jsx';
import SignContract from './SignContract.jsx';
import SignPay from './SignPay.jsx';
import SmzInstruction from './SmzInstruction.jsx';
import Start from './Start.jsx';
import TaxAbout from './TaxAbout.jsx';
import TaxAmount from './TaxAmount.jsx';
import Wallet from './Wallet.jsx';
import WebPush from './WebPush.jsx';

class AuthCodeWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getPopup() {
        const {
            name,
            authCodePopup,
            appStartPopup,
            appBalancePopup,
            appLogoutPopup,
            appNetworkConnectPopup,
            appInstructionApplicationPopup,
            appNewVersionPopup,
            appSignContractPopup,
            appSignPayPopup,
            appWalletPopup,
            appWebPushPopup,
            appNotificationsPopup,
            appFnsActivateInfoPopup,
            appPayInfoPopup,
            appPayCancelPopup,
            appSmzInstructionPopup,
            appJoinCorporationPopup,
            appContractPopup,
            appTaxAboutPopup,
            appTaxAmountPopup,
            serverNotAvailablePopup,
            appCountriesPopup,
            appTownsPopup,
        } = this.props;

        if (name === 'authCodePopup') {
            return {
                isShow: authCodePopup?.isShow,
                render() {
                    return <AuthCode {...authCodePopup} />;
                },
            };
        }

        if (name === 'appStartPopup') {
            return {
                isShow: appStartPopup?.isShow,
                render() {
                    return <Start {...appStartPopup} />;
                },
            };
        }

        if (name === 'appBalancePopup') {
            return {
                isShow: appBalancePopup?.isShow,
                render() {
                    return <Balance {...appBalancePopup} />;
                },
            };
        }

        if (name === 'appLogoutPopup') {
            return {
                isShow: appLogoutPopup?.isShow,
                render() {
                    return <Logout {...appLogoutPopup} />;
                },
            };
        }

        if (name === 'appNetworkConnectPopup') {
            return {
                isShow: appNetworkConnectPopup?.isShow,
                render() {
                    return <NetworkDisconnect {...appNetworkConnectPopup} />;
                },
            };
        }

        if (name === 'appInstructionApplicationPopup') {
            return {
                isShow: appInstructionApplicationPopup?.isShow,
                render() {
                    return <InstructionApplication {...appInstructionApplicationPopup} />;
                },
            };
        }

        if (name === 'appNewVersionPopup') {
            return {
                isShow: appNewVersionPopup?.isShow,
                isFull: true,
                render() {
                    return <NewVersion {...appNewVersionPopup} />;
                },
            };
        }

        if (name === 'appSignContractPopup') {
            return {
                isShow: appSignContractPopup?.isShow,
                render() {
                    return <SignContract {...appSignContractPopup} />;
                },
            };
        }

        if (name === 'appSignPayPopup') {
            return {
                isShow: appSignPayPopup?.isShow,
                render() {
                    return <SignPay {...appSignPayPopup} />;
                },
            };
        }

        if (name === 'appWalletPopup') {
            return {
                isShow: appWalletPopup?.isShow,
                render() {
                    return <Wallet {...appWalletPopup} />;
                },
            };
        }

        if (name === 'appWebPushPopup') {
            return {
                isShow: appWebPushPopup?.isShow,
                render() {
                    return <WebPush {...appWebPushPopup} />;
                },
            };
        }

        if (name === 'appNotificationsPopup') {
            return {
                isShow: appNotificationsPopup?.isShow,
                render() {
                    return <Notifications {...appNotificationsPopup} />;
                },
            };
        }

        if (name === 'appFnsActivateInfoPopup') {
            return {
                isShow: appFnsActivateInfoPopup?.isShow,
                render() {
                    return <FnsActivateInfo {...appFnsActivateInfoPopup} />;
                },
            };
        }

        if (name === 'appPayInfoPopup') {
            return {
                isShow: appPayInfoPopup?.isShow,
                render() {
                    return <PayInfo {...appPayInfoPopup} />;
                },
            };
        }

        if (name === 'appPayCancelPopup') {
            return {
                isShow: appPayCancelPopup?.isShow,
                render() {
                    return <PayCancel {...appPayCancelPopup} />;
                },
            };
        }

        if (name === 'appSmzInstructionPopup') {
            return {
                isShow: appSmzInstructionPopup?.isShow,
                render() {
                    return <SmzInstruction {...appSmzInstructionPopup} />;
                },
            };
        }

        if (name === 'appJoinCorporationPopup') {
            return {
                isShow: appJoinCorporationPopup?.isShow,
                render() {
                    return <JoinCorporation {...appJoinCorporationPopup} />;
                },
            };
        }

        if (name === 'appContractPopup') {
            return {
                isShow: appContractPopup?.isShow,
                render() {
                    return <Contract {...appContractPopup} />;
                },
            };
        }

        if (name === 'appTaxAboutPopup') {
            return {
                isShow: appTaxAboutPopup?.isShow,
                render() {
                    return <TaxAbout {...appTaxAboutPopup} />;
                },
            };
        }

        if (name === 'appTaxAmountPopup') {
            return {
                isShow: appTaxAmountPopup?.isShow,
                render() {
                    return <TaxAmount {...appTaxAmountPopup} />;
                },
            };
        }

        if (name === 'serverNotAvailablePopup') {
            return {
                isShow: serverNotAvailablePopup?.isShow,
                render() {
                    return <ServerNotAvailable {...serverNotAvailablePopup} />;
                },
            };
        }

        if (name === 'appTownsPopup') {
            return {
                isShow: appTownsPopup?.isShow,
                render() {
                    return (
                        <ListPopup
                            {...appTownsPopup}
                            popupName="appTownsPopup"
                            currentName="towns"
                        />
                    );
                },
            };
        }

        if (name === 'appCountriesPopup') {
            return {
                isShow: appCountriesPopup?.isShow,
                render() {
                    return (
                        <ListPopup
                            {...appCountriesPopup}
                            popupName="appCountriesPopup"
                            currentName="countries"
                        />
                    );
                },
            };
        }

        return {};
    }

    render() {
        const { name } = this.props;
        const popup = this.getPopup();

        return (
            <Animate
                className={`body__appPopup _${name} ${popup?.isFull ? '_full' : ''}`}
                isShow={popup.isShow}
            >
                {popup?.render.call(this)}
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        authCodePopup: state.authCodePopup,
        appStartPopup: state.appStartPopup,
        appBalancePopup: state.appBalancePopup,
        appLogoutPopup: state.appLogoutPopup,
        appNetworkConnectPopup: state.appNetworkConnectPopup,
        appInstructionApplicationPopup: state.appInstructionApplicationPopup,
        appNewVersionPopup: state.appNewVersionPopup,
        appSignContractPopup: state.appSignContractPopup,
        appSignPayPopup: state.appSignPayPopup,
        appWalletPopup: state.appWalletPopup,
        appWebPushPopup: state.appWebPushPopup,
        appNotificationsPopup: state.appNotificationsPopup,
        appFnsActivateInfoPopup: state.appFnsActivateInfoPopup,
        appPayInfoPopup: state.appPayInfoPopup,
        appPayCancelPopup: state.appPayCancelPopup,
        appSmzInstructionPopup: state.appSmzInstructionPopup,
        appJoinCorporationPopup: state.appJoinCorporationPopup,
        appContractPopup: state.appContractPopup,
        appTaxAboutPopup: state.appTaxAboutPopup,
        appTaxAmountPopup: state.appTaxAmountPopup,
        serverNotAvailablePopup: state.serverNotAvailablePopup,
        appCountriesPopup: state.appCountriesPopup,
        appTownsPopup: state.appTownsPopup,
    };
}

export default connect(mapStateToProps)(AuthCodeWrapper);

AuthCodeWrapper.propTypes = {
    name: PropTypes.string,
    authCodePopup: PropTypes.object,
    appStartPopup: PropTypes.object,
    appBalancePopup: PropTypes.object,
    appLogoutPopup: PropTypes.object,
    appNetworkConnectPopup: PropTypes.object,
    appInstructionApplicationPopup: PropTypes.object,
    appNewVersionPopup: PropTypes.object,
    appSignContractPopup: PropTypes.object,
    appSignPayPopup: PropTypes.object,
    appWalletPopup: PropTypes.object,
    appWebPushPopup: PropTypes.object,
    appNotificationsPopup: PropTypes.object,
    appFnsActivateInfoPopup: PropTypes.object,
    appPayInfoPopup: PropTypes.object,
    appPayCancelPopup: PropTypes.object,
    appSmzInstructionPopup: PropTypes.object,
    appJoinCorporationPopup: PropTypes.object,
    appContractPopup: PropTypes.object,
    appTaxAboutPopup: PropTypes.object,
    appTaxAmountPopup: PropTypes.object,
    serverNotAvailablePopup: PropTypes.object,
    appCountriesPopup: PropTypes.object,
    appTownsPopup: PropTypes.object,
};
