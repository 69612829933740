import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { joinScript, isError } = this.state;

    return (
        <>
            <LoaderBlock className="join__loader" loaderClassName="_main" isShow={!joinScript && !isError} />
            <div className={`join__content`}>
                <Animate className="join__error" isShow={!!isError}>
                    <img
                        src={require('../../../../img/emodzi/man-shrugging.png')}
                        alt=""
                        className="join__errorIcon"
                    />
                    <p className="join__errorText">
                        Сценарий не найден.
                        <br />
                        Проверьте ссылку или обратитесь в&nbsp;поддержку
                    </p>
                </Animate>
                <div className="join__contentInner">{this.renderBlocks()}</div>
            </div>
        </>
    );
};

export default renderContent;
