import React from 'react';

import { BlockT } from '../types.ts';

const Anket: BlockT = function () {
    const fieldsOrder = this.getFieldsOrder();

    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__blockTitle">
                    Укажите ваши
                    <br />
                    личные данные
                </div>
                <div className="join__blockText">
                    Заполните все поля по порядку
                    <br />
                    сверху — вниз
                </div>
                <div className="join__blockFields">
                    {fieldsOrder.map((name) => this.renderField({ name }))}
                </div>
                {this.renderError()}
                {this.renderButton({ name: 'anket', text: 'Продолжить' })}
            </div>
        </>
    );
};

export default Anket;
