import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import Animate from '../../Animate.jsx';
import Loader from '../../Loader.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import getArticles from '../../../requests/getArticles';
import setSpacesInText from '../../../functions/setSpacesInText';

class InfoPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    pages = {
        'user-agreement': {
            title: 'Пользовательское соглашение',
        },
        policy: {
            title: 'Политика обработки персональных данных',
        },
    };

    async getArticle() {
        const { popupName } = this.props;

        const { article } = await getArticles('legal', {
            params: [{ key: 'name', value: popupName }],
        });

        this.setState({ article });
    }

    componentDidMount() {
        this.getArticle();
    }

    render() {
        const { article } = this.state;
        const { popupName } = this.props;
        const page = this.pages[popupName];

        return (
            <div className="infoPopup">
                <div className="infoPopup__head">
                    <div className="infoPopup__title">{page?.title}</div>
                    <div
                        className="infoPopup__close _col"
                        onClick={() => {
                            handlerPopup({ name: 'appInfoPopup', isShow: false });
                        }}
                    >
                        <i className="infoPopup__closeIcon">
                            <Icon name="close" />
                        </i>
                    </div>
                </div>
                <div className={`infoPopup__content ${!article ? '_hide' : ''}`}>
                    <Animate className="infoPopup__loader _loader" isShow={!article}>
                        <div className="infoPopup__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div
                        className="infoPopup__contentInner"
                        dangerouslySetInnerHTML={{ __html: setSpacesInText(article?.content) }}
                    ></div>
                </div>
            </div>
        );
    }
}

export default InfoPopup;

InfoPopup.propTypes = {
    isShow: PropTypes.bool,
    popupName: PropTypes.string,
};
