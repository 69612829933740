import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import checkIos from '../functions/checkIos';

class JoinSmzInstruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const appLink = checkIos()
            ? 'https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D0%BD%D0%B0%D0%BB%D0%BE%D0%B3/id1437518854?l=en'
            : 'https://play.google.com/store/apps/details?id=com.gnivts.selfemployed&hl=ru';

        return (
            <>
                <div className="join__blockTitle">
                    Регистрация
                    <br />
                    самозанятого
                </div>
                <div className="join__blockText">
                    Выберите способ оформления — через «Мой налог», Госуслуги или кредитные
                    огранизации
                </div>
                <div className="join__smzInstruction">
                    <div className="join__smzInstructionBlock">
                        <div className="join__smzInstructionBlockContent">
                            <div className="join__smzInstructionBlockTitle">Мой налог</div>
                            <div className="join__smzInstructionBlockDescription">
                                Граждане РФ могут стать самозанятыми через приложение Мой налог
                            </div>
                            <div className="join__smzInstructionBlockInfo _row">
                                <img
                                    src={require('../img/crm/joins/smz-country-rf.svg').default}
                                    alt=""
                                    className="join__smzInstructionBlockInfoIcon"
                                />
                                Только для граждан РФ
                            </div>
                        </div>
                        <a
                            className="join__smzInstructionBlockButton _row"
                            href={appLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Скачать «Мой налог»
                        </a>
                    </div>
                    <div className="join__smzInstructionBlock">
                        <div className="join__smzInstructionBlockContent">
                            <div className="join__smzInstructionBlockTitle">Госуслуги</div>
                            <div className="join__smzInstructionBlockDescription">
                                Граждане РФ, одной из стран ЕАЭС или Украины могут стать
                                самозанятыми через Госуслуги
                            </div>
                            <div className="join__smzInstructionBlockInfo _row">
                                {['rf', 'bl', 'arm', 'kg', 'kz', 'ukr'].map((key) => (
                                    <img
                                        key={key}
                                        src={require(`../img/crm/joins/smz-country-${key}.svg`)}
                                        alt=""
                                        className="join__smzInstructionBlockInfoIcon"
                                    />
                                ))}
                            </div>
                        </div>
                        <a
                            className="join__smzInstructionBlockButton _row"
                            href="https://www.gosuslugi.ru/609969/1/form"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Перейти в Госуслуги
                        </a>
                    </div>
                    <div className="join__smzInstructionBlock">
                        <div className="join__smzInstructionBlockContent">
                            <div className="join__smzInstructionBlockTitle">
                                Кредитные огранизации
                            </div>
                            <div className="join__smzInstructionBlockDescription">
                                Граждане РФ, одной из стран ЕАЭС или Украины могут стать
                                самозанятыми через кредитные организации
                            </div>
                            <div className="join__smzInstructionBlockInfo _row">
                                {['rf', 'bl', 'arm', 'kg', 'kz', 'ukr'].map((key) => (
                                    <img
                                        key={key}
                                        src={require(`../img/crm/joins/smz-country-${key}.svg`)}
                                        alt=""
                                        className="join__smzInstructionBlockInfoIcon"
                                    />
                                ))}
                            </div>
                        </div>
                        <a
                            className="join__smzInstructionBlockButton _row"
                            href="https://npd.nalog.ru/credit-orgs/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Список организаций
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme,
    };
}

export default connect(mapStateToProps)(JoinSmzInstruction);

JoinSmzInstruction.propTypes = {
    images: PropTypes.object,
};
