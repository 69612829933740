import I from '../types.ts';

import anketHandler from './steps/anketHandler.ts';
import cardHandler from './steps/cardHandler.ts';
import docsHandler from './steps/docsHandler.ts';
import phoneHandler from './steps/phoneHandler.ts';
import rejectHandler from './steps/rejectHandler.ts';
import startHandler from './steps/startHandler.ts';
import statusHandler from './steps/statusHandler.ts';
import statusIpHandler from './steps/statusIpHandler.ts';
import statusSmzHandler from './steps/statusSmzHandler.ts';

const stepHandler: I['stepHandler'] = async function ({ name }) {
    if (name === 'start') {
        return await startHandler.call(this);
    }

    if (name === 'phone') {
        return await phoneHandler.call(this);
    }

    if (name === 'status') {
        return await statusHandler.call(this);
    }

    if (name === 'status-ip-form') {
        return await statusIpHandler.call(this);
    }

    if (name === 'status-smz-add') {
        return await statusSmzHandler.call(this, 'add');
    }

    if (name === 'status-smz-check') {
        return await statusSmzHandler.call(this, 'check');
    }

    if (name === 'status-smz-number') {
        return await statusSmzHandler.call(this, 'number', true);
    }

    if (name === 'anket') {
        return await anketHandler.call(this);
    }

    if (name === 'docs') {
        return await docsHandler.call(this);
    }

    if (name === 'card') {
        return await cardHandler.call(this);
    }

    if (name === 'reject') {
        return await rejectHandler.call(this);
    }
};

export default stepHandler;
