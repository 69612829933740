import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import checkAuth from '../../../functions/app/checkAuth';
import handlerPopup from '../../../functions/app/handlerPopup';
import checkValueOfEmpty from '../../../functions/checkValueOfEmpty';
import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';
import getFormatPrice from '../../../functions/getFormatPrice';
import getFormatedDate from '../../../functions/getFormatedDate';
import getHeaders from '../../../functions/getHeaders';
import setAnimate from '../../../functions/setAnimate';

import { dispatcher } from '../../../redux/redux';
import Animate from '../../Animate.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Button from '../../Button.jsx';
import Field from '../../Field.jsx';
import Icon from '../../Icon.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import Loader from '../../Loader.jsx';
import Popup from './Popup.jsx';

class SignPay extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            currentBlock: 'info',
            code: {
                value: '',
                error: null,
            },
        };

        this.handlerCode = this.handlerCode.bind(this);
        this.renderBlock = this.renderBlock.bind(this);
    }

    errors = {
        'error-code': 'Неверный код. Попробуйте ещё раз',
    };

    hide() {
        super.hide();

        handlerPopup({ name: 'appSignPayPopup', isShow: false });
    }

    blocksOrder = ['info', 'code'];

    getBlocks() {
        const { currentBlock, isInit } = this.state;

        return isInit ? [{ key: currentBlock }] : [];
    }

    handlerCode({ action, value, error }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const code = { ...newState.code };

                    if (checkValueOfEmpty(value, true)) {
                        code.value = value;
                    }

                    code.error = error || null;

                    newState.code = code;

                    return newState;
                });
            }
        });
    }

    blocks = {
        info: {
            render() {
                const { loadingKey, pays = [] } = this.state;
                const { user } = this.props;
                const { wallets, currentWallet } = user;
                const wallet = wallets.find((item) => item._id === currentWallet);
                const currentCorporation = getCurrentCorporation({ user });

                return (
                    <>
                        <div className="appPopup__description">
                            Подписывая акт, вы даёте согласие на&nbsp;перевод денег по реквизитам
                            ниже
                        </div>
                        {pays.map((pay) => (
                            <div className="appPopup__list" key={pay._id}>
                                <div className="appPopup__listInner">
                                    {pays.length > 1 && (
                                        <div className="appPopup__listItem _row">
                                            <div className="appPopup__listItemValue _support">
                                                Номер акта:
                                            </div>
                                            <div className="appPopup__listItemValue">
                                                № {pay.number}
                                            </div>
                                        </div>
                                    )}
                                    <div className="appPopup__listItem _row">
                                        <div className="appPopup__listItemValue _support">
                                            Дата акта:
                                        </div>
                                        <div className="appPopup__listItemValue">
                                            {getFormatedDate({ date: new Date(pay?.startPeriod) })}{' '}
                                            — {getFormatedDate({ date: new Date(pay?.endPeriod) })}
                                        </div>
                                    </div>
                                    <div className="appPopup__listItem _row">
                                        <div className="appPopup__listItemValue _support">
                                            Компания:
                                        </div>
                                        <div className="appPopup__listItemValue">
                                            {currentCorporation.name}
                                        </div>
                                    </div>
                                    <div className="appPopup__listItem _row">
                                        <div className="appPopup__listItemValue _support">
                                            Сумма:
                                        </div>
                                        <div className="appPopup__listItemValue">
                                            {getFormatPrice(pay?.amount)}₽
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="appPopup__list _withButton">
                            <div className="appPopup__listInner">
                                {user.organization === 'FL' || wallet.type === 'card' ? (
                                    <>
                                        <div className="appPopup__listItem _row">
                                            <div className="appPopup__listItemValue _support">
                                                Номер карты:
                                            </div>
                                            <div className="appPopup__listItemValue">
                                                {wallet.number}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="appPopup__listItem _row">
                                            <div className="appPopup__listItemValue _support">
                                                БИК:
                                            </div>
                                            <div className="appPopup__listItemValue">
                                                {wallet.bic}
                                            </div>
                                        </div>
                                        <div className="appPopup__listItem _row">
                                            <div className="appPopup__listItemValue _support">
                                                Номер счёта:
                                            </div>
                                            <div className="appPopup__listItemValue">
                                                {wallet.number}
                                            </div>
                                        </div>
                                        <div className="appPopup__listItem _row">
                                            <div className="appPopup__listItemValue _support">
                                                ФИО:
                                            </div>
                                            <div className="appPopup__listItemValue">
                                                {wallet.addressatName || '–'}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="appPopup__listButton">
                                <Button
                                    className="_blue"
                                    onClick={() => {
                                        handlerPopup({
                                            name: 'appWalletPopup',
                                            isShow: true,
                                            id: wallet._id,
                                        });
                                    }}
                                >
                                    Редактировать реквизиты
                                </Button>
                            </div>
                        </div>
                        <div className="appPopup__button">
                            <Button
                                showLoader={loadingKey === 'info'}
                                icon={{ type: 'end', name: 'arrow-next-2' }}
                                onClick={() => this.signPay({})}
                            >
                                Смс-код
                            </Button>
                        </div>
                    </>
                );
            },
        },
        code: {
            render() {
                const { code, loadingKey, error } = this.state;

                return (
                    <>
                        <div className="appPopup__description">
                            Введите код подтверждения из смс,
                            <br />
                            чтобы подписать акт
                        </div>
                        <div className="appPopup__fields">
                            <div className="appPopup__field">
                                <Field
                                    keyName="code"
                                    name="code"
                                    value={code.value}
                                    error={code.error}
                                    callback={this.handlerCode}
                                />
                            </div>
                        </div>
                        <div className="appPopup__button">
                            <Button
                                showLoader={loadingKey === 'code'}
                                onClick={() => this.signPay({ send: true })}
                            >
                                Подписать акт
                            </Button>
                        </div>
                        <AnimateChangeUp
                            className="appPopup__errors"
                            renderKey={error}
                            parentStyles={['height']}
                            callback={({ isChangeLen }) => {
                                if (isChangeLen) {
                                    this.setState({ updatedBlocksKey: new Date().getTime() });
                                }
                            }}
                        >
                            {error ? (
                                <div className="appPopup__error">{this.errors[error]}</div>
                            ) : null}
                        </AnimateChangeUp>
                    </>
                );
            },
        },
    };

    renderBlock({ prop: key }) {
        const block = this.blocks[key];

        return <div className="appPopup__block _col">{block?.render?.call(this)}</div>;
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    signPayRequest({ code }) {
        const { pays } = this.state;

        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${process.env.REACT_APP_HARD_API}/pays`,
                    {
                        itemsIds: pays.map((pay) => pay._id),
                        code,
                        ...(code ? { hash: this.state.hash } : {}),
                    },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            if (code) {
                                checkAuth();
                            } else {
                                const { hash } = data;

                                this.setState({ hash });
                            }

                            resolve();
                        } else {
                            const { message } = data;

                            if (message === 'Code is incorrect') {
                                this.setState({ error: 'error-code' });
                                this.handlerCode({ action: 'change', error: 'error' });
                            }

                            reject();
                        }
                    },
                    () => null,
                );
        });
    }

    signPay({ send }) {
        const { appSignPayPopup } = this.props;
        const { payId, callback } = appSignPayPopup;

        const setRequest = async ({ code }) => {
            this.setState({ error: null });

            await this.handlerLoading(code ? 'code' : 'info');

            this.signPayRequest({ code }).then(
                () => {
                    if (!send) {
                        this.setState({ currentBlock: 'code' });

                        const box = this.parent.current.querySelector('.appPopup__innerBox');
                        const { scrollTop } = box;

                        setAnimate({
                            draw: (progress) => {
                                box.scrollTop = scrollTop - progress * scrollTop;
                            },
                            duration: 300,
                            callback: () => null,
                        });
                    } else {
                        const { user } = this.props;
                        const pays = JSON.parse(JSON.stringify(user.pays));
                        const payIndex = pays.findIndex((pay) => pay._id === payId);

                        pays.splice(payIndex, 1);

                        this.hide();

                        if (typeof callback === 'function') {
                            callback();
                        }

                        dispatcher({
                            type: 'user',
                            data: { ...user, pays },
                        });
                    }

                    this.handlerLoading(null);
                },
                () => {
                    this.handlerLoading(null);
                },
            );
        };

        if (!send) {
            setRequest({});
        } else {
            const code = this.state.code.value;

            if (!code || code.length !== 5) {
                this.handlerCode({ action: 'change', error: 'error' });
            } else {
                setRequest({ code });
            }
        }
    }

    getPay() {
        const { appSignPayPopup } = this.props;
        const { payId } = appSignPayPopup;

        axios.get(`${process.env.REACT_APP_API}/pays?id=${payId}`, { headers: getHeaders() }).then(
            (res) => {
                const { success, data } = res.data;

                if (success) {
                    const { items } = data;

                    this.setState({ pays: items, isInit: true });
                }
            },
            () => null,
        );
    }

    componentDidMount() {
        super.componentDidMount();

        this.getPay();
    }

    render() {
        const { pays = [], currentBlock, isInit, updatedBlocksKey } = this.state;

        return (
            <div
                ref={this.parent}
                className={`appPopup _col _signPay _waitInit ${isInit ? '_init' : ''}`}
            >
                <div className="appPopup__inner">
                    <Animate className="appPopup__loader _loader" isShow={!isInit}>
                        <div className="appPopup__loaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <i className="appPopup__icon">
                                <Icon name="file-type-doc" />
                            </i>
                            <div className="appPopup__title _mediumSize">
                                Подписание:
                                <br />
                                {pays
                                    .map(
                                        (innerPay) =>
                                            `акт\u00A0№${innerPay?.number} от ${getFormatedDate({
                                                date: new Date(innerPay?.date),
                                            })}`,
                                    )
                                    .join(', ')}
                            </div>
                            <ListAbsoluteMain
                                className="appPopup__blocks"
                                items={this.getBlocks()}
                                renderItem={this.renderBlock}
                                classNameItem="appPopup__block"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                isNotParamsItem={true}
                                currentItemKey={currentBlock}
                                allItems={this.blocksOrder}
                                minHeight={100}
                                keyRender={updatedBlocksKey}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
        user: state.user,
        appSignPayPopup: state.appSignPayPopup,
    };
}

export default connect(mapStateToProps)(SignPay);

SignPay.propTypes = {
    windowHeight: PropTypes.number,
    user: PropTypes.object,
    appSignPayPopup: PropTypes.object,
};
