import axios from 'axios';

import handlerLoading from '@functions/handlerLoading.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const updateHandler: I['updateHandler'] = async function ({ fields, name, status, nextStep }) {
    const change = this.checkChange(true);
    const resultFields = fields || change.model!;
    let response;

    await handlerLoading.call(this, name);

    try {
        response = await axios.patch<
            {},
            { data: ReqResponseT<{ executorInfo?: any; hash?: string; id?: string }> }
        >(`${process.env.REACT_APP_API}/join`, { fields: resultFields, status }, this.getHeaders());
    } catch (error) {
        await handlerLoading.call(this, undefined);

        return;
    }

    const { success, data } = response.data;

    if (success) {
        const { executorInfo } = data;

        if (executorInfo) {
            await this.init({ fields: executorInfo });
        }

        if (nextStep) {
            await this.setStep(nextStep!);
        }
    }

    this.setState({ error: data.error });

    await handlerLoading.call(this, undefined);

    const { hash, id } = data;

    if (hash && id) {
        localStorage.setItem('joinAuthHash', hash);
        localStorage.setItem('joinAuthId', id);
    }
};

export default updateHandler;
