import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import Avatar from '../../Avatar.jsx';
import joinToCorporation from '../../../functions/app/joinToCorporation';

class JoinCorporation extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.save = this.save.bind(this);
        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appJoinCorporationPopup', isShow: false });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    save() {
        const { appJoinCorporationPopup } = this.props;
        const { corporation } = appJoinCorporationPopup;
        const { id } = corporation;

        this.handlerLoading('save').then(() => {
            joinToCorporation({ id }).then(
                () => {
                    this.hide();
                    this.handlerLoading(null);
                },
                () => {
                    this.handlerLoading(null);
                },
            );
        });
    }

    render() {
        const { loadingKey } = this.state;
        const { appJoinCorporationPopup } = this.props;
        const { corporation } = appJoinCorporationPopup;
        const { logo, name, description } = corporation;

        return (
            <div ref={this.parent} className="appPopup _col _joinCorporation">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <Avatar
                                src={
                                    logo?.path &&
                                    `${process.env.REACT_APP_STATIC}/corporations/${logo.path}`
                                }
                                holder={{
                                    text: name?.[0]?.toUpperCase(),
                                }}
                                className="appPopup__icon"
                                withText={true}
                                iconType={logo?.type}
                            />
                            <div className="appPopup__title">{name}</div>
                            <p className="appPopup__description">{description}</p>
                            <div className="appPopup__button">
                                <Button onClick={this.save} showLoader={loadingKey === 'save'}>
                                    Подключиться
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
        appJoinCorporationPopup: state.appJoinCorporationPopup,
    };
}

export default connect(mapStateToProps)(JoinCorporation);

JoinCorporation.propTypes = {
    windowHeight: PropTypes.number,
    appJoinCorporationPopup: PropTypes.object,
};
