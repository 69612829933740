import React from 'react';

import Button from '@components/button/Button.tsx';
import Icon from '@components/icon/Icon.tsx';

import I from '../types.ts';

const renderTelegram: I['renderTelegram'] = function () {
    return (
        <div className="join__telegram _col">
            <div className="join__telegramButton">
                <Button className="_blue _bigSize">Связаться с нами в Telegram</Button>
            </div>
            <div className="join__telegramDescription">
                Есть вопросы? — <b>напишите нам</b>
                <i className="join__telegramDescriptionIcon">
                    <Icon name="join-telegram-arrow" />
                </i>
            </div>
        </div>
    );
};

export default renderTelegram;
