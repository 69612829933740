import React from 'react';

import handlerPopup from '@functions/handlerPopup.ts';

import { BlockT } from '../types.ts';

const Phone: BlockT = function () {
    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__blockTitle">
                    Введите номер
                    <br />
                    вашего телефона
                </div>
                <div className="join__blockText">
                    Вам поступит звонок на указанный номер&nbsp;телефона
                    {/* Мы отправим на него 5-значный код для авторизации */}
                </div>
                <div className="join__blockFields">{this.renderField({ name: 'phone' })}</div>
                {this.renderError()}
                {this.renderButton({ name: 'phone', text: 'Продолжить', className: '_notTop' })}
                <p className="join__policy">
                    Нажимая на кнопку «Продолжить» я подтверждаю,
                    <br />
                    что&nbsp;ознакомлен и согласен с&nbsp;
                    <span
                        className="_click"
                        onClick={() => {
                            handlerPopup('appInfoPopup', {
                                isShow: true,
                                popupName: 'user-agreement',
                            });
                        }}
                    >
                        пользовательским соглашением
                    </span>{' '}
                    и&nbsp;
                    <span
                        onClick={() => {
                            handlerPopup('appInfoPopup', {
                                isShow: true,
                                popupName: 'policy',
                            });
                        }}
                    >
                        политикой обработки и защиты персональных данных
                    </span>
                </p>
            </div>
        </>
    );
};

export default Phone;
