import React from 'react';

import CodeBox from '@components/CodeBox.jsx';

import phoneHandler from '../methods/steps/phoneHandler.ts';

import { BlockT } from '../types.ts';

const Code: BlockT = function () {
    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__blockTitle">
                    Введите код
                    <br />
                    подтверждения
                </div>
                <div className="join__blockText">
                    {/* Необходимо ввести <b>последние 4 цифры</b> номера телефона, с которого вам
                    поступил звонок */}
                    Необходимо ввести <b>5-значный код</b>, который поступил вам в смс
                </div>
                <div className="join__blockCode">
                    <CodeBox
                        isNumber={true}
                        className="_join"
                        // inputs={[1, 2, 3, 4]}
                        inputs={[1, 2, 3, 4, 5]}
                        callback={this.checkCode.bind(this)}
                    />
                </div>
                {this.renderError()}
                <div
                    className="join__blockBack"
                    onClick={async () => {
                        await this.init({ fields: {} });

                        this.setStep('phone');
                    }}
                >
                    Ввести другой номер
                </div>
                <div
                    className="join__blockBack"
                    onClick={() => {
                        // handlerButton({ name: 'checkPhone', isForce: true });
                        phoneHandler.call(this);
                    }}
                >
                    {/* <Animate
                        className="join__blockBackLoader _loader"
                        isShow={loadingButton === 'checkPhone'}
                    >
                        <div className="join__blockBackLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate> */}
                    {/* Запросить звонок повторно */}
                    Запросить смс повторно
                </div>
            </div>
        </>
    );
};

export default Code;
