import React from 'react';

import { BlockT } from '../types.ts';

const StatusIpForm: BlockT = function () {
    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__blockTitle">
                    Подключение
                    <br />в качестве ИП
                </div>
                <div className="join__blockText">
                    Для продолжения в качестве индивидуального предпринимателя введите ваш ИНН
                </div>
                <div className="join__blockFields">{this.renderField({ name: 'inn' })}</div>
                {this.renderError()}
                {this.renderButton({
                    name: 'status-ip-form',
                    text: 'Продолжить',
                    className: '_notTop',
                })}
            </div>
        </>
    );
};

export default StatusIpForm;
