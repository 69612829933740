import React from 'react';
import PropTypes from 'prop-types';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { items = [], currentName } = this.props;

        return (
            <div className="pagination _row">
                {items.map((name) => {
                    const isCurrent = currentName === name;

                    return (
                        <div
                            className={`pagination__item ${isCurrent ? '_current' : ''}`}
                            key={name}
                        >
                            <div className="pagination__itemInner"></div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default Pagination;

Pagination.propTypes = {
    items: PropTypes.array,
    currentName: PropTypes.string,
};
