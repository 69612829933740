import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Index from './legal/Index.jsx';
import Pages from '../../../components/Pages.jsx';
import Inner from './legal/Inner.jsx';

class Legal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    pages = {
        'settings-legal-index': {
            render() {
                return (
                    <>
                        <Index />
                    </>
                );
            },
        },
        'settings-legal-inner': {
            render() {
                return (
                    <>
                        <Inner />
                    </>
                );
            },
        },
    };

    render() {
        return (
            <div className="settings__pages">
                <Pages
                    classNamePage="settings__page"
                    filter={(page) => page.parentName === 'settings-legal'}
                    pages={this.pages}
                    context={this}
                    fullHeight={false}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Legal);

Legal.propTypes = {
    user: PropTypes.object,
};
