import { dispatcher, store } from '../../redux/redux';

export default function handlerOrderPopup({ action, id, dispatch = true }) {
    const appOrdersPopups = JSON.parse(JSON.stringify(store.getState().appOrdersPopups));

    if (action === 'add') {
        if (!appOrdersPopups.includes(id)) {
            appOrdersPopups.push(id);
        }
    } else {
        const index = appOrdersPopups.indexOf(id);

        if (index !== -1) {
            appOrdersPopups.splice(index, 1);
        }
    }

    if (dispatch) {
        dispatcher({ type: 'appOrdersPopups', data: appOrdersPopups });
    }

    localStorage.setItem('appOrdersPopups', JSON.stringify(appOrdersPopups));
}
