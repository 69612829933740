import React from 'react';

import setSpacesInText from '@functions/setSpacesInText.ts';

import { BlockT } from '../types.ts';

const Docs: BlockT = function () {
    const model = this.state.model!;
    const { docs } = model;

    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__blockTitle">
                    Загрузка
                    <br />
                    документов
                </div>
                {docs.length === 0 ? (
                    <>
                        <div
                            className="join__blockText"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(
                                    'Для вашего сценария документы не требуются.',
                                ),
                            }}
                        />
                        <div className="join__blockFields" />
                    </>
                ) : (
                    <>
                        <div
                            className="join__blockText"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(
                                    'Загрузите фото перечисленных документов, чтобы мы вас идентифицировали и выпустили электронную подпись. Она нужна для подписания договоров и актов.',
                                ),
                            }}
                        />
                        <div className="join__blockFields">
                            {docs.map((doc) => this.renderDoc(doc))}
                        </div>
                    </>
                )}
                {this.renderError()}
                {this.renderButton({ name: 'docs', text: 'Продолжить' })}
                <div
                    className="join__blockBack _top"
                    onClick={async () => {
                        await this.updateHandler({
                            name: 'backDocs',
                            nextStep: 'anket',
                            status: 'anket',
                        });
                    }}
                >
                    Вернуться к основной информации
                </div>
            </div>
        </>
    );
};

export default Docs;
