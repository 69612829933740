import { dispatcher } from '../../redux/redux';
import changePage from '../changePage';
import connectSockets from '../connectSockets';
import { deleteCookie } from '../handlerCookies';

export default function logoutActions() {
    dispatcher({ type: 'isProccessLogout', data: true }).then(() => {
        deleteCookie(process.env.REACT_APP_HASH);

        localStorage.removeItem('user');
        localStorage.removeItem('appOrdersPopups');

        connectSockets(true);

        changePage({ href: 'start' }).then(
            () => {
                dispatcher({ type: 'isProccessLogout', data: false });
                dispatcher({ type: 'user', data: null });
                dispatcher({ type: 'appOrdersPopups', data: [] });
            },
            () => null,
        );
    });
}
