const tabsOrder = [
    'start',
    'phone',
    'code',
    'status',
    'anket',
    'docs',
    'card',
    'check',
    'complete',
] as const;

const tabs = {
    start: {
        content: 'Приветствие',
    },
    phone: {
        content: 'Телефон',
    },
    code: {
        content: 'Подтверждение',
    },
    status: {
        content: 'Статус',
    },
    anket: {
        content: 'Анкета',
    },
    docs: {
        content: 'Документы',
    },
    card: {
        content: 'Карта',
    },
    check: {
        content: 'Проверка',
    },
    reject: {
        content: 'На доработке',
    },
    complete: {
        content: 'Готово',
    },
} as const;

export { tabs, tabsOrder };
